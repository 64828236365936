<template>
  <table id="tableComponent" class="table table-bordered table-striped">
    <thead>
    <tr>
      <th v-for="field in fields" :key='field' @click="sortTable(field)">
        {{ field }}
      </th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="item in tableData" :key='item'>
      <td v-for="field in fields" :key='field'>
        <div @click="handleClick" v-html="item[field]"></div>
      </td>
    </tr>
    </tbody>
  </table>

  <!--Modals deleted -->
  <Teleport to="body">
    <modal-generic :show="modalOpenTipoPromociones" @close="modalOpenTipoPromociones = false" width="500px">
      <template #header>
        <h3>¿Estas seguro?</h3>
      </template>
      <template #body>
        <p>¿Estás seguro que quieres eliminar este tipo de promoción?</p>
      </template>
      <template #footer>
        <button @click="deleteTipoPromocion(tipoPromocionIdDeleted)" class="btn btn-danger">Eliminar</button>
      </template>
    </modal-generic>

    <modal-generic :show="modalOpenTipoOpciones" @close="modalOpenTipoOpciones = false" width="500px">
      <template #header>
        <h3>¿Estas seguro?</h3>
      </template>
      <template #body>
        <p>¿Estás seguro que quieres eliminar esta opción?</p>
      </template>
      <template #footer>
        <button @click="deleteTipoOpciones(tipoOpcionesId)" class="btn btn-danger">Eliminar</button>
      </template>
    </modal-generic>

    <modal-generic :show="modalOpenPromociones" @close="modalOpenPromociones = false" width="500px">
      <template #header>
        <h3>¿Estas seguro?</h3>
      </template>
      <template #body>
        <p>¿Estás seguro que quieres eliminar esta promoción?</p>
      </template>
      <template #footer>
        <button @click="deletePromocion(promocionId)" class="btn btn-danger">Eliminar</button>
      </template>
    </modal-generic>

    <modal-generic :show="modalOpenDeleteViviendas" @close="modalOpenDeleteViviendas = false" width="500px">
      <template #header>
        <h3>¿Estas seguro?</h3>
      </template>
      <template #body>
        <p>¿Estás seguro que quieres eliminar esta vivienda de esta promoción?</p>
      </template>
      <template #footer>
        <button @click="deleteVivienda(viviendaId)" class="btn btn-danger">Eliminar</button>
      </template>
    </modal-generic>

    <modal-generic :show="modalOpenViviendas" @close="modalOpenViviendas = false" width="900px">
      <template #header>
        <h3>Actualizar vivienda</h3>
      </template>
      <template #body>
        <div class="row">
          <div class="col-lg-3">
            <div class="form-group">
              <label>Nº</label>
              <input v-model="num_piso" type="number" class="form-control"/>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group">
              <label>Planta</label>
              <input v-model="planta_piso" type="number" class="form-control"/>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group">
              <label>Tipo vivienda</label>
              <select class="form-control" v-model="tipoVivienda">
                <option value="-1"></option>
                <option v-for="tipo in optionsTipo" :key="tipo.value" :value="tipo.value">{{ tipo.label }}</option>
              </select>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group">
              <label>Superficie construida</label>
              <input v-model="superficie" type="text" class="form-control"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3">
            <div class="form-group">
              <label>Dormitorios</label>
              <input v-model="dormitorios" type="number" class="form-control"/>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group">
              <label>Baños</label>
              <input v-model="banios" type="number" class="form-control"/>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group">
              <label>Jardin/Terraza</label>
              <input v-model="jardinTerraza" type="text" class="form-control"/>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group">
              <label>Precio venta €</label>
              <input v-model="precioVenta" type="text" class="form-control">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3">
            <div class="form-group">
              <label>Tipo</label>
              <input v-model="tipoOther" type="text" class="form-control"/>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group">
              <label>Otros</label>
              <input v-model="otros" type="text" class="form-control">
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <button @click="saveVivienda" class="btn btn-success">Guardar</button>
      </template>
    </modal-generic>

    <modal-generic :show="modalOpenDeleteAgencia" @close="modalOpenDeleteAgencia = false" width="500px">
      <template #header>
        <h3>¿Estas seguro?</h3>
      </template>
      <template #body>
        <p>¿Estás seguro que quieres eliminar esta agencia?</p>
      </template>
      <template #footer>
        <button @click="deleteAgencia(agenciaId)" class="btn btn-danger">Eliminar</button>
      </template>
    </modal-generic>

    <modal-generic :show="modalOpenDeleteUsuario" @close="modalOpenDeleteUsuario = false" width="500px">
      <template #header>
        <h3>¿Estas seguro?</h3>
      </template>
      <template #body>
        <p>¿Estás seguro que quieres eliminar este usuario?</p>
      </template>
      <template #footer>
        <button @click="deleteUsuario(usuarioId)" class="btn btn-danger">Eliminar</button>
      </template>
    </modal-generic>

  </Teleport>
  <notifications position="top right"/>
</template>

<script>

import ModalGeneric from "@/components/modals/ModalGeneric";
import {notify} from "@kyvg/vue3-notification";

export default {
  name: "TableListado",
  components: {ModalGeneric},
  props: {
    tableData: {
      type: Array
    },
    fields: {
      type: Array
    },
  },
  data() {
    return {
      modalOpenTipoPromociones: false,
      tipoPromocionIdDeleted: '',
      modalOpenTipoOpciones: false,
      tipoOpcionesId: '',
      tabla: this.tableData,
      modalOpenPromociones: false,
      promocionId: '',
      modalOpenDeleteViviendas: false,
      viviendaId: '',
      promocionViviendaId: '',
      modalOpenViviendas: false,
      num_piso: '',
      planta_piso: '',
      superficie: '',
      dormitorios: '',
      banios: '',
      jardinTerraza: '',
      precioVenta: '',
      tipoOther: '',
      otros: '',
      optionsTipo: [],
      tipoVivienda: '',
      viviendaEditId: '',
      agenciaId: '',
      modalOpenDeleteAgencia: false,
      usuarioId: '',
      modalOpenDeleteUsuario: false
    }
  },
  methods: {
    handleClick(e) {
      let deleteTipoPromocion = e.target.closest('.deleteTipoPromocion');
      let deleteTipoOpciones = e.target.closest('.deleteTipoOpciones');
      let deletePromociones = e.target.closest('.deletePromocion');
      let deleteViviendas = e.target.closest('.deleteVivienda');
      let copyVivienda = e.target.closest('.copyVivienda');
      let editVivienda = e.target.closest('.editVivienda');
      let deleteAgencia = e.target.closest('.deleteAgencia');
      let deleteUsuario = e.target.closest('.deleteUsuarios');

      if (deleteTipoPromocion) {
        this.modalOpenTipoPromociones = true;
        let tipoPromocionId = e.path[2].getAttribute('data-id');
        this.tipoPromocionIdDeleted = tipoPromocionId;
      } else if (deleteTipoOpciones) {
        this.modalOpenTipoOpciones = true;
        let tipoOpcionId = e.path[2].getAttribute('data-id');
        this.tipoOpcionesId = tipoOpcionId;
      } else if (deletePromociones) {
        this.modalOpenPromociones = true;
        let promocionId = e.path[2].getAttribute('data-id');
        this.promocionId = promocionId;
      } else if (deleteViviendas) {
        this.modalOpenDeleteViviendas = true;
        let viviendaId = e.path[2].getAttribute('data-id');
        this.viviendaId = viviendaId;
        let promocionId = e.path[2].getAttribute('data-promocionid');
        this.promocionViviendaId = promocionId;
      } else if (copyVivienda) {
        this.cloneVivienda(e.path[2].getAttribute('data-id'), e.path[2].getAttribute('data-promocionid'))
      } else if (editVivienda) {
        console.log('entro aqui');
        this.loadViviendaDetalle(e.path[2].getAttribute('data-id'), e.path[2].getAttribute('data-promocionid'))
      } else if (deleteAgencia) {
        let agenciaId = e.path[2].getAttribute('data-id');
        this.agenciaId = agenciaId;
        this.modalOpenDeleteAgencia = true;
      } else if (deleteUsuario) {
        let usuarioId = e.path[2].getAttribute('data-id');
        this.usuarioId = usuarioId;
        this.modalOpenDeleteUsuario = true;
      }
    },
    deleteTipoPromocion(tipoPromocionId) {
      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      fetch(this.apiURL + 'configuracion/tipo-promocion/delete-promocion/' + tipoPromocionId, requestOptions)
          .then(async response => {
            const data = await response.json();
            this.modalOpenTipoPromociones = false;
            let typeMessage = (data.status) ? 'success' : 'error';
            notify({
              title: data.message,
              type: typeMessage
            });
            fetch(this.apiURL + 'configuracion/tipo-promocion/get-listado', requestOptions).then(async response => {
              const data = await response.json();
              this.tabla.splice(0);
              data.aTipoPromociones.forEach((obj) => {
                let tipoPromocion = {
                  ID: obj.id,
                  Nombre: obj.nombre,
                  Acciones: '<a href="/admin/configuracion/nuevo-editar-tipo-promocion/' + obj.id + '"><i class="fa fa-pencil"></i></a>&nbsp;<a data-id="' + obj.id + '" class="deleteTipoPromocion" style="cursor: pointer"><i class="fa fa-trash"></i> </a>'
                }

                this.tabla.push(tipoPromocion);
              })
            })
          })
    },
    deleteTipoOpciones(tipoOpcionId) {
      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      fetch(this.apiURL + 'configuracion/opciones-generales/delete-opcion/' + tipoOpcionId, requestOptions)
          .then(async response => {
            const data = await response.json();
            this.modalOpenTipoOpciones = false;
            let typeMessage = (data.status) ? 'success' : 'error';
            notify({
              title: data.message,
              type: typeMessage
            });
            fetch(this.apiURL + 'configuracion/opciones-generales/get-listado', requestOptions).then(async response => {
              const data = await response.json();
              this.tabla.splice(0);
              data.aOpcionesGenerales.forEach((obj) => {
                let tipoOpciones = {
                  ID: obj.id,
                  Nombre: obj.nombre,
                  Acciones: '<a href="/admin/configuracion/nuevo-editar-opciones-generales/' + obj.id + '"><i class="fa fa-pencil"></i></a>&nbsp;<a class="deleteTipoOpciones" style="cursor: pointer" data-id="' + obj.id + '"><i class="fa fa-trash"></i> </a>'
                }
                this.tabla.push(tipoOpciones);
              })
            })
          })
    },
    deletePromocion(promocionId) {
      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      fetch(this.apiURL + 'promociones/delete-promocion/' + promocionId, requestOptions)
          .then(async response => {
            const data = await response.json();
            this.modalOpenPromociones = false;
            let typeMessage = (data.status) ? 'success' : 'error';
            notify({
              title: data.message,
              type: typeMessage
            });
            fetch(this.apiURL + 'promociones/get-listado', requestOptions).then(async response => {
              const data = await response.json();
              this.tabla.splice(0);
              data.aPromociones.forEach((obj) => {
                let tipoPromocion = {
                  ID: obj.id,
                  Nombre: obj.nombre,
                  Ubicacion: obj.ubicacion,
                  Estado: obj.estado,
                  Fecha: obj.fecha,
                  Acciones: '<a href=""><i class="fa fa-pencil"></i></a>&nbsp;' +
                      '<a href=""><i class="fa-solid fa-building"></i> </a>' +
                      '<a data-id="' + obj.id + '" class="deletePromocion" style="cursor: pointer"><i class="fa fa-trash"></i> </a>'
                }
                this.tabla.push(tipoPromocion);
              })
            })
          })
    },
    deleteVivienda(viviendaId) {
      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      fetch(this.apiURL + 'promociones/delete-vivienda/' + viviendaId, requestOptions)
          .then(async response => {
            const data = await response.json();
            this.modalOpenDeleteViviendas = false;
            let type = (data.status) ? 'success' : 'error';
            notify({
              title: data.message,
              type: type
            })
            this.getListadoViviendas(this.promocionViviendaId);


          });
    },
    cloneVivienda(viviendaId, promocionId) {
      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      fetch(this.apiURL + 'promociones/clone-vivienda/' + viviendaId, requestOptions).then(async response => {
        const data = await response.json();
        let type = (data.status) ? 'success' : 'error';
        notify({
          title: data.message,
          type: type
        })
        this.promocionId = promocionId;
        this.getListadoViviendas(promocionId);
      });
    },
    getListadoViviendas(promocionId) {
      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      fetch(this.apiURL + 'promociones/viviendas/get-listado-viviendas/' + promocionId, requestOptions).then(async response => {
        const data = await response.json();
        this.tabla.splice(0);
        data.aViviendas.forEach((obj) => {
          let vivienda = {
            'Numero': obj.numero,
            'Planta': obj.planta,
            'Tipo': obj.tipo,
            'Dormitorios': obj.dormitorios,
            'Baños': obj.banios,
            'Precio venta': obj.precio_venta,
            'Superficie construida': obj.superficie + " m2",
            'Acciones': '<a data-id="' + obj.id + '" class="editVivienda" style="cursor: pointer"><i class="fa fa-pencil"></i> </a>&nbsp;' +
                '<a data-id="' + obj.id + '" class="copyVivienda" style="cursor: pointer"><i class="fa fa-copy"></i> </a>&nbsp;' +
                '<a data-id="' + obj.id + '" class="deleteVivienda" style="cursor: pointer"><i class="fa fa-trash"></i> </a>'
          }
          this.tabla.push(vivienda);
        });
      });
    },
    loadViviendaDetalle(viviendaId, promocionId) {
      console.log(viviendaId);
      console.log(promocionId);
      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      fetch(this.apiURL + 'promociones/get-tipo-vivienda/' + promocionId, requestOptions).then(async response => {
        const data = await response.json();
        data.aTiposPromociones.forEach((obj) => {
          let tipoPromocion = {
            value: obj.id,
            label: obj.nombre
          }
          this.optionsTipo.push(tipoPromocion);
        });

        //Cargamos el detalle de la vivienda
        fetch(this.apiURL + 'promociones/get-detalle-vivienda/' + viviendaId, requestOptions).then(async responseVivienda => {
          const dataVivienda = await responseVivienda.json();
          this.num_piso = dataVivienda.aViviendas.numero;
          this.planta_piso = dataVivienda.aViviendas.planta;
          this.tipoVivienda = dataVivienda.aViviendas.tipo;
          this.superficie = dataVivienda.aViviendas.superficie;
          this.dormitorios = dataVivienda.aViviendas.dormitorios;
          this.banios = dataVivienda.aViviendas.banios;
          this.jardinTerraza = dataVivienda.aViviendas.jardin_terraza;
          this.precioVenta = dataVivienda.aViviendas.precio;
          this.tipoOther = dataVivienda.aViviendas.tipo_other;
          this.otros = dataVivienda.aViviendas.otros;
          this.viviendaEditId = dataVivienda.aViviendas.id;
          this.promocionId = promocionId;
          this.modalOpenViviendas = true;
        });
      });
    },
    saveVivienda() {
      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      let formData = new FormData();

      formData.append('num_piso', this.num_piso);
      formData.append('planta_piso', this.planta_piso);
      formData.append('tipo_vivienda', this.tipoVivienda);
      formData.append('superficie', this.superficie);
      formData.append('dormitorios', this.dormitorios);
      formData.append('banios', this.banios);
      formData.append('jardin_terraza', this.jardinTerraza);
      formData.append('precio_venta', this.precioVenta);
      formData.append('tipo', this.tipoOther);
      formData.append('otros', this.otros);
      formData.append('promocion_id', this.promocionId);

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData
      };
      fetch(this.apiURL + 'promociones/viviendas/save-edit-viviendas/' + this.viviendaEditId, requestOptions).then(async response => {
        const data = await response.json();
        this.modalOpenViviendas = false;
        var type = (data.status) ? 'success' : 'danger';
        notify({
          title: data.message,
          type: type
        });
        this.getListadoViviendas(this.promocionId);

      });
    },
    deleteAgencia(agenciaId) {
      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      fetch(this.apiURL + 'configuracion/delete-agencia/' + agenciaId, requestOptions)
          .then(async response => {
            const data = await response.json();
            this.modalOpenDeleteAgencia = false;
            let type = (data.status) ? 'success' : 'error';
            notify({
              title: data.message,
              type: type
            })
            this.getListadoAgencias();

          });
    },
    getListadoAgencias() {
      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      fetch(this.apiURL + 'configuracion/get-listado-agencias', requestOptions).then(async response => {
        const data = await response.json();
        this.tabla.splice(0);
        data.aAgencias.forEach((obj) => {
          let agencias = {
            ID: obj.id,
            Nombre: obj.nombre,
            Acciones: '<a href="/admin/configurcion/nueva-editar-agencia/' + obj.id + '"><i class="fa fa-pencil"></i></a>&nbsp;<a data-id="' + obj.id + '" class="deleteAgencia" style="cursor: pointer"><i class="fa fa-trash"></i> </a>'
          }
          this.tabla.push(agencias);
        })
      })
    },
    deleteUsuario(usuarioId) {
      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      fetch(this.apiURL + 'usuarios/delete-usuario/' + usuarioId, requestOptions)
          .then(async response => {
            const data = await response.json();
            this.modalOpenDeleteUsuario = false;
            let type = (data.status) ? 'success' : 'error';
            notify({
              title: data.message,
              type: type
            })
            this.getListadoUsuarios();

          });
    },
    getListadoUsuarios() {
      let url = 'usuarios/get-listado-usuarios';
      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };

      fetch(this.apiURL + url, requestOptions).then(async response => {
        const data = await response.json();
        data.aUsuarios.forEach((obj) => {
          let usuario = {
            ID: obj.id,
            'Nombre y Apellidos': obj.nombre_apellidos,
            DNI: obj.dni,
            'Codigo usuario': obj.estado,
            Agencia: obj.agencia,
            Acciones: '<a href="/admin/usuarios/editar-usuario/' + obj.id + '"><i class="fa fa-pencil"></i></a>&nbsp;' +
                '<a data-id="' + obj.id + '" class="deleteUsuarios" style="cursor: pointer"><i class="fa fa-trash"></i> </a>'
          }
          this.tabla.push(usuario);
        })
      })
    }
  }
}
</script>

<style scoped>
/*https://blog.philho.com/posts/vue-sorting-tables/*/
</style>
