<template>
  <div id="wrapper">
    <LeftMenu></LeftMenu>
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <TopMenuBackofficeMain></TopMenuBackofficeMain>
        <div class="container-fluid">
          <h1 class="h3 mb-2 text-gray-800 mb-4">{{ $t("backoffice.opcionesGenerales.title") }}</h1>
          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-lg-8">
                  <h6 class="m-0 font-weight-bold text-primary">{{
                      $t("backoffice.opcionesGenerales.titleListado")
                    }}</h6>
                </div>
                <div class="col-lg-4 text-right">
                  <button @click="newOpcionesGenerales" class="btn btn-primary"><i class="fa-solid fa-plus"></i>
                    {{ $t("backoffice.opcionesGenerales.buttonAdd") }}
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <TableListado :fields='fields' :tableData="opcionesData"></TableListado>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from "@/components/backoffice/LeftMenu";
import TopMenuBackofficeMain from "@/components/backoffice/TopMenuBackoffice";
import TableListado from "@/components/backoffice/TableListado";

export default {
  name: "ListadoOpcionesGenerales",
  components: {TableListado, TopMenuBackofficeMain, LeftMenu},
  data() {
    return {
      opcionesData: [],
      fields: ['ID', 'Nombre', 'Acciones'],
    }
  },
  mounted() {
    let myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };
    fetch(this.apiURL + 'configuracion/opciones-generales/get-listado', requestOptions).then(async response => {
      const data = await response.json();
      data.aOpcionesGenerales.forEach((obj) => {
        let tipoOpciones = {
          ID: obj.id,
          Nombre: obj.nombre,
          Acciones: '<a href="/admin/configuracion/nuevo-editar-opciones-generales/' + obj.id + '"><i class="fa fa-pencil"></i></a>&nbsp;<a class="deleteTipoOpciones" style="cursor: pointer" data-id="' + obj.id + '"><i class="fa fa-trash"></i> </a>'
        }
        this.opcionesData.push(tipoOpciones);
      })
    })
  },
  setup() {
    /*const opcionesData = [
      {
        ID: "1",
        Nombre: "Parque infantil",
        Acciones: '<a href=""><i class="fa fa-pencil"></i></a>'
      },
      {
        ID: "2",
        Nombre: "Piscina exterior",
        Acciones: '<a href=""><i class="fa fa-pencil"></i></a>'
      }
    ];
    const fields = [
      'ID', 'Nombre', 'Acciones'
    ]
    return {opcionesData, fields}*/
  },
  methods: {
    newOpcionesGenerales() {
      window.location.href = '/admin/configuracion/nuevo-editar-opciones-generales';
    }
  }
}
</script>

<style scoped>

</style>
