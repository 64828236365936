<template>
  <div id="wrapper">
    <LeftMenu></LeftMenu>
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <TopMenuBackofficeMain></TopMenuBackofficeMain>
        <div class="container-fluid">
          <h1 class="h3 mb-2 text-gray-800 mb-4" v-if="tipoPromocionId === undefined">
            {{ $t("backoffice.tiposPromociones.titleNew") }}</h1>
          <h1 class="h3 mb-2 text-gray-800 mb-4" v-if="tipoPromocionId !== undefined">
            {{ $t("backoffice.tiposPromociones.titleEditar") }}</h1>
          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-lg-8">
                  <h6 class="m-0 font-weight-bold text-primary" v-if="tipoPromocionId === undefined">
                    {{ $t("backoffice.tiposPromociones.titleNew") }}</h6>
                  <h6 class="m-0 font-weight-bold text-primary" v-if="tipoPromocionId !== undefined">
                    {{ $t("backoffice.tiposPromociones.titleEditar") }}</h6>
                </div>
                <div class="col-lg-4 text-right">

                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Nombre</label>
                    <input v-model="nombre" type="text" class="form-control">
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group" style="margin-top: 35px">
                    <div class="custom-control custom-checkbox">
                      <input v-model="isActivo" type="checkbox" class="custom-control-input"
                             id="isDestacada">
                      <label class="custom-control-label" for="isDestacada">Activo</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6"></div>
                <div class="col-lg-6 text-right">
                  <button class="btn btn-danger">Cancelar</button>&nbsp;
                  <button @click="guardarPromocion" class="btn btn-primary">Guardar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <notifications position="top right"/>
</template>

<script>
import LeftMenu from "@/components/backoffice/LeftMenu";
import TopMenuBackofficeMain from "@/components/backoffice/TopMenuBackoffice";
import {useRoute} from 'vue-router';
import {notify} from "@kyvg/vue3-notification";

export default {
  name: "NuevaEditarTipoPromocion",
  components: {TopMenuBackofficeMain, LeftMenu},
  data() {
    return {
      tipoPromocionId: null,
      nombre: null,
      isActivo: true
    }
  },
  mounted() {
    const route = useRoute()

    if (typeof route.params.id !== undefined) {
      this.tipoPromocionId = route.params.id;
      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      fetch(this.apiURL + 'configuracion/get-detalle-tipo-promocion/' + this.tipoPromocionId, requestOptions).then(async response => {
        const data = await response.json();
        this.nombre = data.aPromocion.nombre;
        this.isActivo = data.aPromocion.isActivo;
      });


    }
    console.log(this.tipoPromocionId);
  },
  setup() {


  },
  methods: {
    guardarPromocion() {
      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      let formData = new FormData();
      formData.append('nombre', this.nombre);
      formData.append('isActivo', this.isActivo);
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData
      };

      let url = '';
      if (this.tipoPromocionId === undefined) {
        url = 'configuracion/tipo-promocion/add-edit-promocion';
      } else {
        url = 'configuracion/tipo-promocion/add-edit-promocion/' + this.tipoPromocionId;
      }

      fetch(this.apiURL + url, requestOptions).then(async response => {
        const data = await response.json();
        let typeMessage = (data.status) ? 'success' : 'error';
        notify({
          title: data.message,
          type: typeMessage
        });
        setTimeout(function () {
          window.location.href = '/admin/configuracion/tipo-promociones';
        }, 3000);
      });

    }
  }
}
</script>

<style scoped>

</style>
