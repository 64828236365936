<template>
  <div id="wrapper">
    <LeftMenu></LeftMenu>
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <TopMenuBackofficeMain></TopMenuBackofficeMain>
        <div class="container-fluid">
          <h1 class="h3 mb-2 text-gray-800 mb-4">{{ $t("backoffice.tiposPromociones.title") }}</h1>
          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-lg-8">
                  <h6 class="m-0 font-weight-bold text-primary">{{
                      $t("backoffice.tiposPromociones.titleListado")
                    }}</h6>
                </div>
                <div class="col-lg-4 text-right">
                  <button @click="newTipoPromocion" class="btn btn-primary"><i class="fa-solid fa-plus"></i>
                    {{ $t("backoffice.tiposPromociones.buttonAdd") }}
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <TableListado :fields='fields' :tableData="tiposPromocionesData"></TableListado>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import LeftMenu from "@/components/backoffice/LeftMenu";
import TopMenuBackofficeMain from "@/components/backoffice/TopMenuBackoffice";
import TableListado from "@/components/backoffice/TableListado";


export default {
  name: "ListadoTiposPromociones",
  components: {TableListado, TopMenuBackofficeMain, LeftMenu},
  data() {
    return {
      tiposPromocionesData: [],
      fields: ['ID', 'Nombre', 'Acciones'],
      urlEndpoint: this.apiURL,
      tipoPromocionId: ''
    }
  },
  mounted() {
    let myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };
    fetch(this.apiURL + 'configuracion/tipo-promocion/get-listado', requestOptions).then(async response => {
      const data = await response.json();
      data.aTipoPromociones.forEach((obj) => {
        let tipoPromocion = {
          ID: obj.id,
          Nombre: obj.nombre,
          Acciones: '<a href="/admin/configuracion/nuevo-editar-tipo-promocion/' + obj.id + '"><i class="fa fa-pencil"></i></a>&nbsp;<a data-id="'+obj.id+'" class="deleteTipoPromocion" style="cursor: pointer"><i class="fa fa-trash"></i> </a>'
        }
        this.tiposPromocionesData.push(tipoPromocion);
      })
    })

  },
  methods: {
    newTipoPromocion() {
      window.location.href = '/admin/configuracion/nuevo-editar-tipo-promocion';
    },


  }
}
</script>

<style scoped>

</style>
