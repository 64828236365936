<template>
  <div id="sliderHome" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="@/assets/media/slider/slider1.jpg" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="@/assets/media/slider/slider2.jpg" class="d-block w-100" alt="...">
      </div>
      <!--<div class="carousel-item">
        <img src="" class="d-block w-100" alt="...">
      </div>-->
    </div>
    <!--<button class="carousel-control-prev" type="button" data-bs-target="#sliderHome"
            data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#sliderHome"
            data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>-->
  </div>
</template>

<script>
import * as Bootstrap from 'bootstrap'

export default {
  name: "CarouselForm",

  mounted() {
    var myCarousel = document.querySelector("#sliderHome")
    new Bootstrap.Carousel(myCarousel)
  }
}
</script>

<style scoped>
.carousel-item {
  height: 80vh;
}
</style>
