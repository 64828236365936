<template>
  <div id="modalLogin" class="modal fade" ref="modaLogin" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="myform bg-coorporativo">
            <h1 class="text-center">{{ $t("modals.login.title") }}</h1>
            <div class="mb-3 mt-4">
              <label for="exampleInputEmail1" class="form-label">{{ $t("modals.login.email") }}</label>
              <input v-model="email" type="email" class="form-control" id="exampleInputEmail1"
                     aria-describedby="emailHelp">
            </div>
            <div class="mb-3">
              <label for="exampleInputPassword1" class="form-label">{{ $t("modals.login.password") }}</label>
              <input v-model="password" type="password" class="form-control" id="exampleInputPassword1">
            </div>
            <div v-if="isError" class="alert alert-danger">
              {{ msgError }}
            </div>
            <button @click="login" class="btn btn-light mt-3">{{ $t("modals.login.buttonLogin") }}
            </button>
            <p><a href="#">{{ $t("modals.login.backupPassword") }}</a></p>
          </div>
        </div>
        <!--<div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>-->
      </div>
    </div>
  </div>
  <!--<notifications position="top right" />-->
</template>

<script>

import $ from 'jquery'

export default {
  name: "loginModal",
  data() {
    return {
      isError: false,
      msgError: '',
      email: null,
      password: null
    }
  },
  methods: {
    login() {
      let formData = new FormData();
      formData.append('_username', this.email);
      formData.append('_password', this.password);
      const requestOptions = {
        method: 'POST',
        body: formData
      };
      fetch(this.apiURL + 'login_check', requestOptions).then(async response => {
        const data = await response.json();
        console.log(data);
        if (data.token !== 'null') {
          localStorage.setItem('token', data.token);
          //Obtenemos el usuario que se loguea

          let myHeaders = new Headers();
          myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
          const requestOptionsProfile = {
            method: 'GET',
            headers: myHeaders,
          };
          fetch(this.apiURL + 'get-profile', requestOptionsProfile).then(async responseLogin => {
            const dataProfile = await responseLogin.json();
            localStorage.setItem('user', JSON.stringify(dataProfile.user));

            window.location.href = '/admin';
            $("#modalLogin").modal('hide');
          });

        } else {
          this.isError = true;
          this.msgError = data.message;
        }
      })


      return false;
    }
  }
}
</script>

<style scoped>
.modal-content {
  width: 80%;
  margin: 0 auto;
}

.modal-body {
  padding: 0;
}

.btn-close {
  position: absolute;
  right: 0;
  padding: 1em;
}

h1 {
  font-size: 2.3em;
  font-weight: bold;
}

.myform {
  padding: 2em;
  max-width: 100%;
  color: #fff;
  box-shadow: 0 4px 6px 0 rgba(22, 22, 26, 0.18);

@media (max-width: 576px) {
  max-width:

90%;
  margin:

0 auto

;
}

}
.form-control:focus {
  box-shadow: inset 0 -1px 0 #7e7e7e;
}

.form-control {
  background-color: inherit;
  color: #fff;
  padding-left: 0;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #fff;
}

.myform .btn {
  width: 100%;
  font-weight: 800;
  background-color: #fff;
  border-radius: 0;
  padding: 0.5em 0;
}

.myform .btn:hover {
  background-color: inherit;
  color: #fff;
  border-color: #fff;
}

p {
  text-align: center;
  padding-top: 2em;
  color: grey;
}

p a {
  color: #e1e1e1;
  text-decoration: none;
}

p a:hover {
  color: #fff;
}
</style>
