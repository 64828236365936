<template>
  <div id="wrapper">
    <LeftMenu></LeftMenu>
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <TopMenuBackofficeMain></TopMenuBackofficeMain>
        <div class="container-fluid">
          <h1 class="h3 mb-2 text-gray-800 mb-4">Nueva agencia</h1>
          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-lg-8">
                  <h6 class="m-0 font-weight-bold text-primary">Datos</h6>
                </div>
                <div class="col-lg-4 text-right">
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Logo</label>
                    <input :src="srcLogo" type="file" class="form-control" @change="saveLogo">
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Nombre</label>
                    <input type="text" class="form-control" v-model="nombre">
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>CIF</label>
                    <input type="text" class="form-control" v-model="cif">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-lg-8">
                  <h6 class="m-0 font-weight-bold text-primary">Documentación</h6>
                </div>
                <div class="col-lg-4 text-right">
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Contrado firmado con HOUSE-IN</label>
                    <input :src="srcDocHouseIn" type="file" class="form-control" @change="saveFirmadoHouseIn">
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Escritura sociedad</label>
                    <input :src="srcEscritura" type="file" class="form-control" @change="saveEscrituraSociedad">
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Certificado corriente pago de SS</label>
                    <input :src="srcCertificadoSS" type="file" class="form-control" @change="saveCertificadoSS">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Certificado corriente pago de Hacienda</label>
                    <input :src="srcHacienda" type="file" class="form-control" @change="saveCertificadoHacienda">
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Certificado titularidad de la cuenta bancaria</label>
                    <input :src="srcCuentaBancaria" type="file" class="form-control" @change="saveCertificadoBancario">
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Documento de identidad del gerente</label>
                    <input :src="srcDocGerente" type="file" class="form-control" @change="saveDocumentoGerente">
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="row mb-4">
            <div class="col-lg-6"></div>
            <div class="col-lg-6 text-right">
              <button @click="cancelarAgencia" class="btn btn-danger">Cancelar</button>&nbsp;
              <button @click="guardarAgencia" class="btn btn-primary">Guardar</button>&nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <notifications position="top right"/>
</template>

<script>
import LeftMenu from "@/components/backoffice/LeftMenu";
import TopMenuBackofficeMain from "@/components/backoffice/TopMenuBackoffice";
import {notify} from "@kyvg/vue3-notification";
import {useRoute} from "vue-router";

export default {
  name: "NuevaEditarAgencia",
  components: {TopMenuBackofficeMain, LeftMenu},
  data() {
    return {
      logoAgencia: null,
      nombre: '',
      cif: '',
      contratoFirmado: null,
      escrituraSociedad: null,
      certificadoCorrienteSS: null,
      certificadoCorrienteHacienda: null,
      certificadoBanco: null,
      documentoIdentidad: null,
      agenciaId: null,
      srcLogo: '',
      valueLogo: '',
      srcDocHouseIn: '',
      srcEscritura: '',
      srcCertificadoSS: '',
      srcHacienda: '',
      srcCuentaBancaria: '',
      srcDocGerente: ''
    }
  },
  mounted() {
    const route = useRoute()
    let myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };
    if (typeof route.params.id !== undefined && typeof route.params.id !== 'undefined') {
      this.agenciaId = route.params.id;
      fetch(this.apiURL + 'configuracion/get-detalle-agencia/' + this.agenciaId, requestOptions).then(async response => {
        let data = await response.json();
        console.log(data);
        this.srcLogo = data.aAgencia.logo;
        this.nombre = data.aAgencia.nombre;
        this.cif = data.aAgencia.cif;
        this.srcDocHouseIn = data.aAgencia.contratoHouseIn;
        this.srcEscritura = data.aAgencia.escrituraSociedad;
        this.srcCertificadoSS = data.aAgencia.certificadoSS;
        this.srcHacienda = data.aAgencia.certificadoHacienda;
        this.srcCuentaBancaria = data.aAgencia.certificadoBanco;
        this.srcDocGerente = data.aAgencia.docGerente;
      });
    }
  },
  methods: {
    cancelarAgencia() {

    },
    guardarAgencia() {
      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      let formData = new FormData();
      formData.append('logo', this.logoAgencia);
      formData.append('nombre', this.nombre);
      formData.append('cif', this.cif);
      formData.append('contradoHouseIn', this.contratoFirmado);
      formData.append('escrituraSociedad', this.escrituraSociedad);
      formData.append('certificadoSS', this.certificadoCorrienteSS);
      formData.append('certificadoHacienda', this.certificadoCorrienteHacienda);
      formData.append('certificadoTitularidad', this.certificadoBanco);
      formData.append('documentoGerente', this.documentoIdentidad);
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData
      };
      let url = (this.agenciaId === null) ? 'configuracion/save-editar-agencia' : 'configuracion/save-editar-agencia/' + this.agenciaId;
      fetch(this.apiURL + url, requestOptions).then(async response => {
        const data = await response.json();
        console.log(data);
        var type = (data.status) ? 'success' : 'error';
        notify({
          title: data.message,
          type: type
        });
        setTimeout(function(){
          window.location.href = '/admin/promociones';
        },3000);
      });
    },
    saveLogo(e) {
      this.logoAgencia = e.target.files[0];
    },
    saveFirmadoHouseIn(e) {
      this.contratoFirmado = e.target.files[0];
    },
    saveEscrituraSociedad(e) {
      this.escrituraSociedad = e.target.files[0];
    },
    saveCertificadoSS(e) {
      this.certificadoCorrienteSS = e.target.files[0];
    },
    saveCertificadoHacienda(e) {
      this.certificadoCorrienteHacienda = e.target.files[0]
    },
    saveDocumentoGerente(e) {
      this.documentoIdentidad = e.target.files[0];
    },
    saveCertificadoBancario(e) {
      this.certificadoBanco = e.target.files[0];
    }
  }
}
</script>

<style scoped>

</style>
