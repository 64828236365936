<template>
  <div id="wrapper">
    <LeftMenu></LeftMenu>
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <TopMenuBackofficeMain></TopMenuBackofficeMain>
        <div class="container-fluid">
          <h1 class="h3 mb-2 text-gray-800 mb-4">{{ $t("backoffice.promociones.title") }}</h1>
          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-lg-8">
                  <h6 class="m-0 font-weight-bold text-primary">{{ $t("backoffice.promociones.titleListado") }}</h6>
                </div>
                <div class="col-lg-4 text-right">
                  <button @click="nuevaPromocion" class="btn btn-primary"><i class="fa-solid fa-plus"></i>
                    {{ $t("backoffice.promociones.buttonAdd") }}
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <TableListado :fields='fields' :tableData="promocionesData"></TableListado>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from "@/components/backoffice/LeftMenu";
import TopMenuBackofficeMain from "@/components/backoffice/TopMenuBackoffice";
import TableListado from "@/components/backoffice/TableListado";
import {useRoute} from "vue-router";

export default {
  name: "ListadoPromociones",
  components: {TableListado, TopMenuBackofficeMain, LeftMenu},
  data() {
    return {
      fields: ['ID', 'Nombre', 'Ubicacion', 'Estado', 'Fecha', 'Acciones'],
      promocionesData: [],
      estadoPromocion: ''
    }
  },
  setup() {
    /*const promocionesData = [
      {
        ID: "1",
        Nombre: "Promoción prueba",
        Ubicacion: 'Barcelona',
        Estado: 'Activo',
        Fecha: '05/10/2022',
        Acciones: '<a href=""><i class="fa fa-pencil"></i></a>'
      },
      {
        ID: "2",
        Nombre: "Promoción en lleida",
        Ubicacion: 'Lleida',
        Estado: 'Activo',
        Fecha: '15/10/2022',
        Acciones: '<a href="">Editar</a>'
      }
    ];*/
  },
  mounted() {
    const route = useRoute()

    let url = '';
    if (typeof route.params.id !== 'undefined') {
      this.estadoPromocion = route.params.estado;
      url = 'promociones/get-listado/' + this.estadoPromocion;
    } else {
      url = 'promociones/get-listado';
    }
    let myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };


    fetch(this.apiURL + url, requestOptions).then(async response => {
      const data = await response.json();
      data.aPromociones.forEach((obj) => {
        let tipoPromocion = {
          ID: obj.id,
          Nombre: obj.nombre,
          Ubicacion: obj.ubicacion,
          Estado: obj.estado,
          Fecha: obj.fecha,
          Acciones: '<a href="/admin/promociones/editar/' + obj.id + '"><i class="fa fa-pencil"></i></a>&nbsp;' +
              '<a href="/admin/promociones/' + obj.id + '/vivienda"><i class="fa-solid fa-building"></i> </a>' +
              '<a data-id="' + obj.id + '" class="deletePromocion" style="cursor: pointer"><i class="fa fa-trash"></i> </a>'
        }
        this.promocionesData.push(tipoPromocion);
      })
    })

  },
  methods: {
    nuevaPromocion() {
      window.location.href = '/admin/promociones/nuevo';
    }
  }
}
</script>

<style scoped>

</style>
