export default {
  "home": {
    "titleHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])},
    "titlePropiedades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROPIEDADES"])},
    "titlePaginas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PÁGINAS"])}
  },
  "filter": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige tu casa"])},
    "filterLugares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los lugares"])},
    "filterTipos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los tipos"])},
    "filterDormitorios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dormitorios"])},
    "filterPrecio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio máximo"])},
    "buscar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])}
  },
  "promotions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promociones destacadas"])}
  },
  "modals": {
    "login": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso"])},
      "buttonLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
      "backupPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Has olvidado tu contraseña?"])}
    }
  },
  "backoffice": {
    "leftmenu": {
      "titlePromociones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promociones"])},
      "titleNuevaPromocion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva"])},
      "titleListadoPromocion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listado"])},
      "titleBorradorPromociones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrador"])},
      "titleBajaPromociones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baja"])},
      "titleConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])},
      "titleConfigTipoPromociones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo viviendas"])},
      "titleConfigOpcionesGenerales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones generales"])},
      "titleConfigAgencias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agencias"])},
      "titleUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios"])},
      "titleNuevoUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo"])},
      "titleListadoUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listado"])},
      "titleBajaUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baja"])}
    },
    "promociones": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promociones"])},
      "titleListado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listado promociones"])},
      "buttonAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir"])},
      "titleNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva promoción"])},
      "titleEditar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar promocion"])}
    },
    "opcionesGenerales": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones generales"])},
      "titleListado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listado opciones generales"])},
      "buttonAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir"])},
      "titleNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva opción general"])},
      "titleEditar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar opción general"])}
    },
    "viviendas": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viviendas"])},
      "titleListado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listado viviendas"])},
      "buttonAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir"])},
      "cancelar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
    },
    "tiposPromociones": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipos viviendas"])},
      "titleListado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listado tipos viviendas"])},
      "buttonAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir"])},
      "titleNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo tipo vivienda"])},
      "titleEditar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar tipo vivienda"])}
    },
    "agencias": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agencias"])},
      "titleListado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listado agencias"])},
      "buttonAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir"])},
      "titleNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva agencia"])},
      "titleEditar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar agencia"])}
    }
  },
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])}
}