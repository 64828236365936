<template>
  <TopMenu></TopMenu>
  <div class="container">
    <MenuItemsHome></MenuItemsHome>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-2  mt-5 pl-5" style="    margin-top: 350px!important;">
          <h2>{{ aPromocion.nombre }}</h2>
          <h5 class="mt-4"><i class="fa-solid fa-location-dot"></i>
            {{ aPromocion.municipio + "/" + aPromocion.provincia }}</h5>
          <h5 class="mt-3"><i class="fa-solid fa-euro-sign"></i> Desde <strong>€{{ aPromocion.valorMinimo }}</strong>
          </h5>
          <h5 class="mt-3"><i class="fa-solid fa-calendar-days"></i> {{ aPromocion.fechaPromocion }}</h5>
        </div>
        <div class="col-10">
          <CarouselPromocion :aImagenes="aPromocion.aImagenes"></CarouselPromocion>

        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="bg-filter">
            <div class="container-fluid">
              <div class="row pt-3 pb-3">
                <div class="col-6">
                  <span>
                    <a class="pr-2" href=""><i class="fa fa-home"></i> Home</a>
                    <span class="pr-2">·</span>
                    <a class="pr-2" href="">Promociones</a>
                    <span class="pr-2">·</span>
                    <a class="pr-2" href="">{{ aPromocion.nombre }}</a> </span>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <a class="pr-5" href="#descripcion">Descripción</a>
                    <a class="pr-5" href="#viviendas">Viviendas</a>
                    <a class="pr-5" href="#entorno">Entorno</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <div class="container-fluid">
            <div class="row">
              <div class="col-9">
                <div class="row" id="descripcion">
                  <div class="col-12">
                    <p>{{ aPromocion.descripcion1 }}</p>
                    <p><strong>Entrega:</strong> {{ aPromocion.fechaPromocion }}</p>
                  </div>
                  <div class="col-12 bg-filter pt-3 pl-3 pr-3">
                    <div class="row">
                      <div class="col-4" v-for="item in aPromocion.aOpciones" :key="item.id">
                        <p>{{ item.nombre }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-3">
                    <p>{{ aPromocion.descripcion2 }}</p>
                  </div>
                  <div class="col-12">

                    <div class="col-lg-6 offset-lg-3">
                      <div class="form-inline">
                        <div class="dropdown">
                          <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownDocumentos"
                                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Documentos
                          </button>
                          <div class="dropdown-menu" aria-labelledby="dropdownDocumentos">
                            <a class="dropdown-item" :href="itemDoc.ruta" v-for="itemDoc in aPromocion.aDocumentos"
                               :key="itemDoc.id">{{ itemDoc.nombre }}</a>
                          </div>
                        </div>
                        <div class="ml-2">
                          <button class="btn btn-primary"><i class="fa fa-video"></i> Vídeo</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-4" id="viviendas">
                  <div class="col-12">
                    <h4 class="text-center">Viviendas disponibles</h4>
                    <table class="table table-hover">
                      <thead>
                      <tr>
                        <th scope="col">Dormitorios</th>
                        <th scope="col">Precios</th>
                        <th scope="col">m2</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(itemVivienda, index) in aPromocionViviendas" :key="index">
                        <td><a style="cursor: pointer" @click="toogleSubTable(index)"> {{
                            itemVivienda.dormitorios
                          }}</a></td>
                        <td><a style="cursor: pointer" @click="toogleSubTable(index)">{{
                            itemVivienda.precioMin + "-" + itemVivienda.precioMax
                          }}€</a></td>
                        <td><a style="cursor: pointer" @click="toogleSubTable(index)">{{
                            itemVivienda.superMin + "-" + itemVivienda.superMax
                          }}m2</a></td>
                      </tr>
                      <tr v-for="(itemVivienda, index) in aPromocionViviendas"
                          :key="index">
                        <td colspan="3" v-if="itemVivienda.isOpen">
                          <table class="table table-hover">
                            <thead>
                            <tr>
                              <th>Nº</th>
                              <th>Puerta</th>
                              <th>Tipo</th>
                              <th>Dormitorios</th>
                              <th>Baños</th>
                              <th>Superficie</th>
                              <th>Jardin/Terraza</th>
                              <th>Precio</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(property, index) in aElementosViviendas" :key="index">
                              <td>{{ property.planta }}</td>
                              <td>{{ property.num_puerta }}</td>
                              <td>{{ property.tipo }}</td>
                              <td>{{ property.dormitorios }}</td>
                              <td>{{ property.banios }}</td>
                              <td>{{ property.superficie }}</td>
                              <td>{{ property.jardin_terraza }}</td>
                              <td>{{ property.precio_venta }}</td>
                            </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-3 sticky-top">
                <form class="col-lg-10 offset-lg-1">
                  <h6 class="text-center">¿Podemos ayudarte?</h6>
                  <h6 class="text-center"><strong>Contacta con nosotros</strong></h6>
                  <div class="row justify-content-center">
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="Tu nombre">
                    </div>
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="Tu email">
                    </div>
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="Tu teléfono">
                    </div>
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="Código postal">
                    </div>
                    <div class="form-inline">
                      <input type="checkbox">Acepto la política de privacidad
                    </div>
                  </div>
                  <div class="form-group mt-2">
                    <button class="btn btn-success btn-block">Enviar</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

</style>
<script>

import MenuItemsHome from "@/components/home/MenuItemsHome";
import {useRoute} from "vue-router";
import TopMenu from "@/components/global/top-menu";
import CarouselPromocion from "@/page/front/promociones/CarouselPromocion.vue";

export default {
  name: "DetallePromocion",
  components: {CarouselPromocion, TopMenu, MenuItemsHome},
  data() {
    return {
      aPromocion: {},
      aViviendas: [],
      aPromocionViviendas: [],
      aElementosViviendas: []
    }
  },
  mounted() {
    const route = useRoute()
    let myHeaders = new Headers();
    //myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    if (typeof route.params.id !== undefined) {
      console.log('id');
      /*fetch(this.urlDomain + 'get-detalle-promocion/' + route.params.id, requestOptions).then(response => {
        const data = response.json();
        this.aPromocion = data.aPromocion;
        this.aPromocionViviendas = data.aPromocion.aViviendas;
      });*/
      fetch(this.urlDomain + 'get-detalle-promocion/' + route.params.id, requestOptions)
          .then(response => response.json())
          .then(promocion => {
            this.aPromocion = promocion.aPromocion;
            this.aPromocionViviendas = promocion.aPromocion.aViviendas;
          })
    }

  },
  methods: {
    toogleSubTable(index) {
      /*this.aPromocionViviendas.forEach((objVivienda) => {
        objVivienda.isOpen = false;
      })*/
      this.aPromocionViviendas[index].isOpen = !this.aPromocionViviendas[index].isOpen;
      this.aElementosViviendas = this.aPromocionViviendas[index].aElementosViviendas;
    }
  }
}
</script>

<style scoped>

</style>
