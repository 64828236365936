<template>
  <nav style="background-color: #030539" class="navbar navbar-expand-lg navbar-dark" aria-label="Eighth navbar example">
    <div class="container">
      <a class="navbar-brand" href="#"></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample07"
              aria-controls="navbarsExample07" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarsExample07">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link active" href="#"><i class="fa-brands fa-facebook-f"></i></a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" href="#">
              <i class="fa-brands fa-linkedin-in"></i>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" href="#">
              <i class="fa-brands fa-google-plus-g"></i>
            </a>
          </li>
          <li class="nav-item">
            <hr/>
          </li>
          <li class="nav-item">
            <a class="nav-link active" href="mailto:contact@housein.com">
              contact@housein.com
            </a>
          </li>
        </ul>
        <ul class="navbar-nav ml-auto">
          <li class="nav-item" v-if="!isLogin">
            <a data-bs-toggle="modal" data-bs-target="#modalLogin" style="cursor: pointer" class="nav-link active">Acceso</a>
          </li>
          <li class="nav-item dropdown" v-if="isLogin">
            <a style="color: white;" class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button"
               aria-haspopup="true"
               aria-expanded="false">{{ userName }}</a>
            <div class="dropdown-menu">
              <a class="dropdown-item" @click="goToPanel">Panel administración</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" @click="closeSession"><i class="fa-solid fa-right-from-bracket"></i> Cerrar
                sesión</a>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link active">Inscribirse</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <LoginModal></LoginModal>
</template>

<script>
import LoginModal from "@/components/modals/ModalLogin";

export default {
  name: "top-menu",
  components: {LoginModal},
  data() {
    return {
      isLogin: false,
      userName: ''
    }
  },
  mounted() {
    this.getMenuLogin()
  },
  methods: {
    getMenuLogin() {
      if (localStorage.getItem('user') !== null) {
        let user = JSON.parse(localStorage.getItem('user'));
        this.userName = user.nombre + " " + user.apellidos;
        this.isLogin = true;
      }
    },
    closeSession() {
      if (localStorage.getItem('user') !== 'undefined') {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        this.isLogin = false;
      }
    },
    goToPanel() {
      window.location.href = '/admin';
    }
  }
}
</script>

<style scoped>

</style>

