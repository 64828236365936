<template>
  <nav class="navbar navbar-expand-lg navbar-light  rounded" aria-label="Eleventh navbar example">
    <div class="container-fluid">
      <a class="navbar-brand" href="/"><img width="150" height="100" src="@/assets/media/img/logo.png"/> </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSubItem"
              aria-controls="navbarSubItem" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSubItem">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">{{ $t("home.titleHome") }}</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="dropdownPropiedades" data-bs-toggle="dropdown"
               aria-expanded="false">{{ $t("home.titlePropiedades") }}</a>
            <ul class="dropdown-menu" aria-labelledby="dropdown09">
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="dropdownPaginas" data-bs-toggle="dropdown"
               aria-expanded="false">{{ $t("home.titlePaginas") }}</a>
            <ul class="dropdown-menu" aria-labelledby="dropdown09">
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "menu-items-home"
}
</script>

<style scoped>

</style>
