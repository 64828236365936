<template>
  <div class="container">
    <div class="row mt-4">
      <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 mb-2" v-for="promocion in promotions" :key="promocion">
        <div class="card" v-on:click="goToPromocion(promocion.id)">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-xs-12 col-sm-12">
                <img :src="promocion.fotoPrincipal" class="img-fluid" style="width: 250px;height: 150px;">
              </div>
              <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                <h5 class="card-title">{{ promocion.nombre }}</h5>
                <p class="card-text"><i class="fa-solid fa-location-dot"></i> {{ promocion.direccion }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--<div class="col-lg-2 col-md-2 col-xs-12 col-sm-12 mb-2" v-for="promocion in promotions" :key="promocion">
        <div class="card">
          <img :src="promocion.fotoPrincipal" class="card-img-top img-fluid" style="height: 230px;">
          <div class="card-body">
            <h5 class="card-title">{{ promocion.nombre }}</h5>
            <p class="card-text">{{ promocion.descripcion }}</p>
            <a :href="'/detalle-promocion/'+promocion.id" class="btn btn-primary">Detalle promoción</a>
          </div>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "PromotionsResult",
  props: {
    promotions: {
      type: Object
    }
  },
  methods: {
    goToPromocion(promocionId) {
      window.location.href = '/detalle-promocion/' + promocionId;
    }
  }
}
</script>

<style scoped>

</style>
