<template>
  <div id="wrapper">
    <LeftMenu></LeftMenu>
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <TopMenuBackofficeMain></TopMenuBackofficeMain>
        <div class="container-fluid">
          <h1 class="h3 mb-2 text-gray-800 mb-4">{{ $t("backoffice.viviendas.title") }}</h1>
          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-lg-8">
                  <h6 class="m-0 font-weight-bold text-primary">{{
                      $t("backoffice.viviendas.titleListado")
                    }} - Promoción {{ nombrePromocion }}</h6>
                </div>
                <div class="col-lg-4 text-right">
                  <div class="row">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-2"></div>
                    <div class="col-lg-2"></div>
                    <div class="col-lg-2" style="margin-right: 15px">
                      <div class="dropdown">
                        <button class="btn btn-primary dropdown-toggle" id="dropdownadd" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                          {{ $t("backoffice.viviendas.buttonAdd") }}
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownadd">
                          <a style="cursor: pointer" @click="handleClick" class="openManualmente dropdown-item">Manualmente</a>
                          <a style="cursor: pointer" class="openPlantilla dropdown-item"
                             @click="handleClick">Plantilla</a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-2">
                      <button @click="cancelarViviendas" class="btn btn-danger">
                        {{ $t("backoffice.viviendas.cancelar") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <TableListado :fields='fields' :tableData="viviendasData"></TableListado>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <modal-generic :show="modalOpenViviendas" @close="modalOpenViviendas = false" width="900px">
    <template #header>
      <h3>Nueva vivienda</h3>
    </template>
    <template #body>
      <div class="row">
        <div class="col-lg-3">
          <div class="form-group">
            <label>Nº</label>
            <input v-model="num_piso" type="number" class="form-control"/>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label>Planta</label>
            <input v-model="planta_piso" type="number" class="form-control"/>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label>Tipo vivienda</label>
            <select class="form-control" v-model="tipoVivienda">
              <option value="-1"></option>
              <option v-for="tipo in optionsTipo" :key="tipo.value" :value="tipo.value">{{ tipo.label }}</option>
            </select>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label>Superficie construida</label>
            <input v-model="superficie" type="text" class="form-control"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <div class="form-group">
            <label>Dormitorios</label>
            <input v-model="dormitorios" type="number" class="form-control"/>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label>Baños</label>
            <input v-model="banios" type="number" class="form-control"/>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label>Jardin/Terraza</label>
            <input v-model="jardinTerraza" type="text" class="form-control"/>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label>Precio venta €</label>
            <input v-model="precioVenta" type="text" class="form-control">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <div class="form-group">
            <label>Tipo</label>
            <input v-model="tipoOther" type="text" class="form-control">
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label>Otros</label>
            <input v-model="otros" type="text" class="form-control">
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <button @click="saveVivienda" class="btn btn-success">Añadir</button>
    </template>
  </modal-generic>

  <modal-generic :show="modalOpenViviendasPlantilla" @close="modalOpenViviendasPlantilla = false" width="900px">
    <template #header>
      <div class="row">
        <div class="col-lg-10">
          <h3>Importar plantilla</h3>
        </div>
        <div class="col-lg-2">
          <a style="cursor: pointer;color:#4e73df;" @click="downloadPlantilla">
            <span><i class="fa fa-download"></i> Plantilla</span>
          </a>
        </div>
      </div>
    </template>
    <template #body>
      <div class="form-group">
        <label>Archivo plantilla</label>
        <input type="file" class="form-control" @change="importFileCsv">
      </div>
      <div class="row" style="height: 250px; overflow: scroll">
        <div class="col-lg-12">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>Número</th>
              <th>Planta</th>
              <th>Tipo vivienda</th>
              <th>Superficie construida</th>
              <th>Dormitorios</th>
              <th>Baños</th>
              <th>Jardin/Terraza</th>
              <th>Precio venta</th>
              <th>Tipo</th>
              <th>Otros</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="viviendas in viviendasList" :key="viviendas">
              <td>
                <input type="text" class="form-control numeroVivienda" :value="viviendas.numero"/>
              </td>
              <td>
                <input type="text" class="form-control plantaVivienda" :value="viviendas.planta"/>
              </td>
              <td>
                <input type="text" class="form-control tipoVivienda" :value="viviendas.tipo"/>
              </td>
              <td>
                <input type="text" class="form-control superficieVivienda" :value="viviendas.superficie"/>
              </td>
              <td>
                <input type="text" class="form-control dormitoriosVivienda" :value="viviendas.dormitorios"/>
              </td>
              <td>
                <input type="text" class="form-control baniosVivienda" :value="viviendas.banios"/>
              </td>
              <td>
                <input type="text" class="form-control jardinVivienda" :value="viviendas.jardin_terraza"/>
              </td>
              <td>
                <input type="text" class="form-control precioVivienda" :value="viviendas.precio"/>
              </td>
              <td>
                <input type="text" class="form-control tipoVivendaOtro" :value="viviendas.tiposOtro">
              </td>
              <td>
                <input type="text" class="form-control otrosVivienda" :value="viviendas.otros">
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template #footer>
      <button class="btn btn-success" @click="guardarViviendasPlantilla">Guardar</button>
    </template>
  </modal-generic>
  <notifications position="top right"/>
</template>

<script>
import LeftMenu from "@/components/backoffice/LeftMenu";
import TopMenuBackofficeMain from "@/components/backoffice/TopMenuBackoffice";
import TableListado from "@/components/backoffice/TableListado";
import ModalGeneric from "@/components/modals/ModalGeneric";
import {useRoute} from "vue-router";
import {notify} from "@kyvg/vue3-notification";

export default {
  name: "ListadoViviendas",
  components: {ModalGeneric, TableListado, TopMenuBackofficeMain, LeftMenu},
  data() {
    return {
      numPisos: [],
      viviendasList: [],
      viviendasData: [],
      fields: ['Numero', 'Planta', 'Tipo vivienda', 'Dormitorios', 'Baños', 'Precio venta', 'Superficie construida',
        'Tipo', 'Otros', 'Acciones'],
      modalOpenViviendas: false,
      modalOpenViviendasPlantilla: false,
      optionsTipo: [],
      tipoVivienda: '',
      num_piso: '',
      planta_piso: '',
      superficie: '',
      dormitorios: '',
      banios: '',
      jardinTerraza: '',
      precioVenta: '',
      tipoOther: '',
      otros: '',
      promocionId: '',
      nombrePromocion: '',
      urlPlantilla: this.urlDomain + 'plantilla-viviendas.xlsx'
    }
  },
  mounted() {
    const route = useRoute()

    if (typeof route.params.id !== undefined) {
      this.promocionId = route.params.id;
      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };

      fetch(this.apiURL + 'promociones/viviendas/get-listado-tipo-promocion/' + route.params.id, requestOptions).then(async response => {
        const data = await response.json();
        console.log(data);
        this.optionsTipo = [];
        data.aTiposPromocion.forEach((obj) => {
          let tipoPromocion = {
            value: obj.id,
            label: obj.nombre
          }
          this.optionsTipo.push(tipoPromocion);
        });
        this.nombrePromocion = data.oPromocion.nombre;
      });

      this.getListadoViviendas(route.params.id);
    }
  },
  methods: {
    handleClick(e) {
      let openManualmente = e.target.closest('.openManualmente');
      let openPlantilla = e.target.closest('.openPlantilla');
      if (openManualmente) {
        this.modalOpenViviendas = true;
      }
      if (openPlantilla) {
        this.modalOpenViviendasPlantilla = true;
      }
    },
    cancelarViviendas() {
      window.location.href = '/admin/promociones';
    },
    openModalManual() {
      this.modalOpenViviendas = true;
    },
    saveVivienda() {
      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      let formData = new FormData();

      formData.append('num_piso', this.num_piso);
      formData.append('planta_piso', this.planta_piso);
      formData.append('tipo_vivienda', this.tipoVivienda);
      formData.append('superficie', this.superficie);
      formData.append('dormitorios', this.dormitorios);
      formData.append('banios', this.banios);
      formData.append('jardin_terraza', this.jardinTerraza);
      formData.append('precio_venta', this.precioVenta);
      formData.append('promocion_id', this.promocionId);
      formData.append('tipo', this.tipoOther);
      formData.append('otros', this.otros);

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData
      };
      fetch(this.apiURL + 'promociones/viviendas/save-edit-viviendas', requestOptions).then(async response => {
        const data = await response.json();
        this.modalOpenViviendas = false;
        var type = (data.status) ? 'success' : 'danger';
        notify({
          title: data.message,
          type: type
        });
        this.getListadoViviendas(this.promocionId);

      });
    },
    getListadoViviendas(promocionId) {
      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      fetch(this.apiURL + 'promociones/viviendas/get-listado-viviendas/' + promocionId, requestOptions).then(async response => {
        const data = await response.json();
        console.log(data);
        data.aViviendas.forEach((obj) => {
          let vivienda = {
            'Numero': obj.numero,
            'Planta': obj.planta,
            'Tipo vivienda': obj.tipo,
            'Dormitorios': obj.dormitorios,
            'Baños': obj.banios,
            'Precio venta': obj.precio_venta,
            'Superficie construida': obj.superficie + " m2",
            'Tipo': obj.tipo_other,
            'Otros': obj.otros,
            'Acciones': '<a data-promocionid="' + this.promocionId + '" data-id="' + obj.id + '" class="editVivienda" style="cursor: pointer"><i class="fa fa-pencil"></i> </a>&nbsp;' +
                '<a data-promocionid="' + this.promocionId + '" data-id="' + obj.id + '" class="copyVivienda" style="cursor: pointer"><i class="fa fa-copy"></i> </a>&nbsp;' +
                '<a data-promocionid="' + this.promocionId + '" data-id="' + obj.id + '" class="deleteVivienda" style="cursor: pointer"><i class="fa fa-trash"></i> </a>'
          }
          this.viviendasData.push(vivienda);
        });
      });
    },
    importFileCsv(e) {
      let fileCsv = e.target.files[0];
      this.viviendasList = [];
      console.log(fileCsv);
      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      let formData = new FormData();
      formData.append('fileCsv', fileCsv);
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData
      };
      fetch(this.apiURL + 'promociones/viviendas/read-file-csv', requestOptions).then(async response => {
        const data = await response.json();
        if (data.status) {
          data.aViviendas.forEach((objVivienda) => {
            let vivienda =
                {
                  numero: objVivienda.numero,
                  planta: objVivienda.planta,
                  tipo: objVivienda.tipo_vivienda,
                  superficie: objVivienda.superficie,
                  dormitorios: objVivienda.dormitorios,
                  banios: objVivienda.banios,
                  jardin_terraza: objVivienda.jardin_terraza,
                  precio: objVivienda.precio + "€",
                  tiposOtro: objVivienda.tipo,
                  otros: objVivienda.otros
                };
            this.viviendasList.push(vivienda);
          });
          e.target.value = '';
        } else {
          notify({
            title: data.message,
            type: 'error'
          })
        }
      });
    },
    guardarViviendasPlantilla() {
      console.log(this.numPisos);
      let aNumVivienda = [];
      let aPlantaVivienda = [];
      let aTipoVivienda = [];
      let aSuperficieVivienda = [];
      let aDormitorioVivienda = [];
      let aBanioVivienda = [];
      let aJardinVivienda = [];
      let aPrecioVivienda = [];
      let aTipoOtroVivienda = [];
      let aOtrosVivienda = [];

      let numVivienda = document.getElementsByClassName('numeroVivienda');
      for (let i = 0; i < numVivienda.length; i++) {
        aNumVivienda.push(numVivienda[i].value);
      }
      let plantaVivienda = document.getElementsByClassName('plantaVivienda');
      for (let e = 0; e < plantaVivienda.length; e++) {
        aPlantaVivienda.push(plantaVivienda[e].value);
      }
      let tipoVivienda = document.getElementsByClassName('tipoVivienda');
      for (let j = 0; j < tipoVivienda.length; j++) {
        aTipoVivienda.push(tipoVivienda[j].value);
      }
      let superficieVivienda = document.getElementsByClassName('superficieVivienda');
      for (let x = 0; x < superficieVivienda.length; x++) {
        aSuperficieVivienda.push(superficieVivienda[x].value);
      }
      let dormitoriosVivienda = document.getElementsByClassName('dormitoriosVivienda');
      for (let k = 0; k < dormitoriosVivienda.length; k++) {
        aDormitorioVivienda.push(dormitoriosVivienda[k].value);
      }
      let baniosVivienda = document.getElementsByClassName('baniosVivienda');
      for (let m = 0; m < baniosVivienda.length; m++) {
        aBanioVivienda.push(baniosVivienda[m].value);
      }
      let jardinVivienda = document.getElementsByClassName('jardinVivienda');
      for (let n = 0; n < jardinVivienda.length; n++) {
        aJardinVivienda.push(jardinVivienda[n].value);
      }
      let precioVivienda = document.getElementsByClassName('precioVivienda');
      for (let u = 0; u < precioVivienda.length; u++) {
        aPrecioVivienda.push(precioVivienda[u].value);
      }

      let tipoOtroVivienda = document.getElementsByClassName('tipoVivendaOtro');
      for (let x = 0; x < tipoOtroVivienda.length; x++) {
        aTipoOtroVivienda.push(tipoOtroVivienda[x].value);
      }

      let otrosVivienda = document.getElementsByClassName('otrosVivienda');
      for (let o = 0; o < otrosVivienda.length; o++) {
        aOtrosVivienda.push(otrosVivienda[o].value);
      }

      let formData = new FormData();
      formData.append('numVivienda', aNumVivienda);
      formData.append('plantaVivienda', aPlantaVivienda);
      formData.append('tipoVivienda', aTipoVivienda);
      formData.append('superficieVivienda', aSuperficieVivienda);
      formData.append('dormitorioVivienda', aDormitorioVivienda);
      formData.append('baniosVivienda', aBanioVivienda);
      formData.append('jardinVivienda', aJardinVivienda);
      formData.append('precioVivienda', aPrecioVivienda);
      formData.append('tipoOtroVivienda', aTipoOtroVivienda);
      formData.append('otroVivienda', aOtrosVivienda);

      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData
      };
      fetch(this.apiURL + 'promociones/viviendas/subida-masiva/' + this.promocionId, requestOptions).then(async response => {
        const data = await response.json();
        console.log(data);
        var type = (data.status) ? 'success' : 'danger';
        notify({
          title: data.message,
          type: type
        });
        this.modalOpenViviendasPlantilla = false;
        this.getListadoViviendas(this.promocionId);
      });

    },
    downloadPlantilla() {
      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      fetch(this.apiURL + 'promociones/generate-excel-plantilla/' + this.promocionId, requestOptions).then(async response => {
        const data = await response.json();
        var link = document.createElement("a");
        // If you don't know the name or want to use
        // the webserver default set name = ''
        link.href = this.urlDomain + '/' + data.rutaArchivo;
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    }
  }
}
</script>

<style scoped>

</style>
