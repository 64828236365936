import {createRouter, createWebHistory} from 'vue-router'
import Home from "@/page/Home";
import Login from "@/page/Login";
import Dashboard from "@/page/backoffice/Dashboard";
import ListadoPromociones from "@/page/backoffice/promociones/ListadoPromociones";
import NuevaPromocion from "@/page/backoffice/promociones/NuevaPromocion";
import ListadoTiposPromociones from "@/page/backoffice/configuracion/tipos-promociones/ListadoTiposPromociones";
import ListadoOpcionesGenerales from "@/page/backoffice/configuracion/opciones-generales/ListadoOpcionesGenerales";
import NuevaEditarTipoPromocion from "@/page/backoffice/configuracion/tipos-promociones/NuevaEditarTipoPromocion";
import NuevoEditarOpcionesGenerales
    from "@/page/backoffice/configuracion/opciones-generales/NuevoEditarOpcionesGenerales";
import ListadoAgencias from "@/page/backoffice/configuracion/agencias/ListadoAgencias";
import ListadoViviendas from "@/page/backoffice/promociones/viviendas/ListadoViviendas";
import NuevaEditarAgencia from "@/page/backoffice/configuracion/agencias/NuevaEditarAgencia";
import ListadoUsuarios from "@/page/backoffice/usuarios/ListadoUsuarios.vue";
import NuevoEditarUsuario from "@/page/backoffice/usuarios/NuevoEditarUsuario.vue";
import DetallePromocion from "@/page/front/promociones/DetallePromocion";
import ListadoPromocionesBusqueda from "@/page/front/promociones/ListadoPromocionesBusqueda";



const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/admin',
        name: 'Admin',
        component: Dashboard,

    },
    {
        path: '/admin/promociones',
        name: 'Promociones',
        component: ListadoPromociones,
    },
    {
        path: '/admin/promociones/:estado',
        name: 'PromocionesBorrador',
        component: ListadoPromociones
    },
    {
        path: '/admin/promociones/nuevo',
        name: 'Nuevas promociones',
        component: NuevaPromocion
    },
    {
        path: '/admin/promociones/editar/:id',
        name: 'EditarPromociones',
        component: NuevaPromocion
    },
    {
        path: '/admin/promociones/:id/vivienda',
        name: 'ListadoViviendasPromociones',
        component: ListadoViviendas
    },
    {
        path: '/admin/configuracion/tipo-promociones',
        name: 'TipoPromociones',
        component: ListadoTiposPromociones
    },
    {
        path: '/admin/configuracion/nuevo-editar-tipo-promocion',
        name: 'NuevoTipoPromocion',
        component: NuevaEditarTipoPromocion
    },
    {
        path: '/admin/configuracion/nuevo-editar-tipo-promocion/:id',
        name: 'EditarTipoPromocion',
        component: NuevaEditarTipoPromocion
    },
    {
        path: '/admin/configuracion/opciones-generales',
        name: 'OpcionesGenerales',
        component: ListadoOpcionesGenerales
    },
    {
        path: '/admin/configuracion/nuevo-editar-opciones-generales',
        name: 'NuevoOpcionesGenerales',
        component: NuevoEditarOpcionesGenerales
    },
    {
        path: '/admin/configuracion/nuevo-editar-opciones-generales/:id',
        name: 'EditarOpcionesGenerales',
        component: NuevoEditarOpcionesGenerales
    },
    {
        path: '/admin/configuracion/listado-agencias',
        name: 'ListadoAgencias',
        component: ListadoAgencias
    },
    {
        path: '/admin/configuracion/nueva-editar-agencia',
        name: 'NuevoEditarAgencia',
        component: NuevaEditarAgencia
    },
    {
        path: '/admin/configurcion/nueva-editar-agencia/:id',
        name: 'EditarAgencia',
        component: NuevaEditarAgencia
    },
    {
        path: '/admin/usuarios/listado',
        name: 'ListadoUsuarios',
        component: ListadoUsuarios
    },
    {
        path: '/admin/usuarios/nuevo-usuario',
        name: 'NuevoUsuario',
        component: NuevoEditarUsuario
    },
    {
        path: '/admin/usuarios/editar-usuario/:id',
        name: 'EditarUsuario',
        component: NuevoEditarUsuario
    },
    {
        path: '/detalle-promocion/:id',
        name: 'DetallePromocion',
        component: DetallePromocion
    },
    {
        path: '/listado-promociones/busqueda',
        name: 'ListadoPromocionesBusqueda',
        component: ListadoPromocionesBusqueda
    }
]

const router = createRouter({history: createWebHistory(), routes})

router.beforeEach(async (to) => {
    const publicPages = ['Home', 'Login', 'DetallePromocion', 'ListadoPromocionesBusqueda'];
    const authRequired = !publicPages.includes(to.name);

    console.log(to.path);
    console.log(localStorage.getItem('token'));

    if (authRequired && (localStorage.getItem('token') === null)) {
        return '/';
    }

})
export default router
