<template>
  <div id="wrapper">
    <LeftMenu></LeftMenu>
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <TopMenuBackofficeMain></TopMenuBackofficeMain>
        <div class="container-fluid">
          <h1 class="h3 mb-2 text-gray-800 mb-4" v-if="usuarioId === undefined">
            Nuevo usuario</h1>
          <h1 class="h3 mb-2 text-gray-800 mb-4" v-if="usuarioId !== undefined">
            Editar usuario</h1>
          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-lg-8">
                  <h6 class="m-0 font-weight-bold text-primary" v-if="usuarioId === undefined">
                    Nuevo usuario</h6>
                  <h6 class="m-0 font-weight-bold text-primary" v-if="usuarioId !== undefined">
                    Editar usuario</h6>
                </div>
                <div class="col-lg-4 text-right">

                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Nombre</label>
                    <input type="text" class="form-control" v-model="nombreUsuario">
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Apellidos</label>
                    <input type="text" class="form-control" v-model="apellidosUsuario">
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>DNI</label>
                    <input type="text" class="form-control" v-model="dniUsuario">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Dirección</label>
                    <input type="text" class="form-control" v-model="direccion"/>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label>País</label>
                    <Multiselect @change="selectedProvincia($event)"
                                 v-model="valuePais"
                                 :options="optionsPais"/>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label>Provincia</label>
                    <Multiselect @change="selectedCiudad($event)" :searchable="true"
                                 v-model="valueProvincia"
                                 :options="optionsProvincia"/>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label>Ciudad</label>
                    <Multiselect :searchable="true"
                                 v-model="valueCiudad"
                                 :options="optionsCiudad"/>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label>Código postal</label>
                    <input type="text" class="form-control" v-model="codigoPostal">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3" :hidden="!isVisibleCodigoUsuario">
                  <div class="form-group">
                    <label>Código de usuario</label>
                    <input type="text" class="form-control" v-model="codigoUsuario">
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="form-group">
                    <label>Nombre usuario</label>
                    <input type="text" class="form-control" v-model="nombreUsuarioAcceso">
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="form-group">
                    <label>Contraseña</label>
                    <input type="password" class="form-control" v-model="password">
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="form-group">
                    <label>Tipo usuario</label>
                    <Multiselect :searchable="true" @select="selectedTipoUsuario"
                                 v-model="valueTipoUsuario"
                                 :options="optionsTipoUsuario"/>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Teléfono</label>
                    <input type="text" class="form-control" v-model="telefono"/>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Correo electrónico</label>
                    <input type="text" class="form-control" v-model="correo">
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Función</label>
                    <input type="text" class="form-control" v-model="funcion">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group" :hidden="!isVisibleNombreComercial">
                    <label>Nombre comercial</label>
                    <input type="text" class="form-control" v-model="nombreComercial"/>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group" :hidden="!isVisibleOficina">
                    <label>Oficina</label>
                    <input type="text" class="form-control" v-model="oficina"/>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group" :hidden="!isVisibleAgencia">
                    <label>Agencia</label>
                    <Multiselect :searchable="true"
                                 v-model="valueAgencia"
                                 :options="optionsAgencia"/>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group" :hidden="!isVisibleComisionAgencia">
                    <label>Comisión acordada Agencia</label>
                    <div class="input-group">
                      <input type="number" class="form-control" v-model="comisionAgencia">
                      <div class="input-group-append">
                        <label class="input-group-text">%</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group" :hidden="!isVisibleComisionCaptador">
                    <label>Comisión acordada Captador</label>
                    <div class="input-group">
                      <input type="number" class="form-control" v-model="comisionCaptador">
                      <div class="input-group-append">
                        <label class="input-group-text">%</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group" :hidden="!isVisibleComision">
                    <label>Comisión acordada</label>
                    <div class="input-group">
                      <input type="number" class="form-control" v-model="comision">
                      <div class="input-group-append">
                        <label class="input-group-text">%</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group" :hidden="!isVisibleNombreContactoMaster">
                    <label>Nombre y contacto del Master</label>
                    <input type="text" class="form-control" v-model="nombreContactoMaster">
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group" :hidden="!isVisibleNombreContactoDelegado">
                    <label>Nombre y contacto del Delegado</label>
                    <input type="text" class="form-control" v-model="nombreContactoDelegado">
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group" :hidden="!isVisibleCursoHerramientas">
                    <label>Curso de herramientas</label>
                    <input type="text" class="form-control" v-model="cursoHerramientas">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card shadow mb-4" :hidden="!isVisibleDocumentos">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-lg-8">
                  <h6 class="m-0 font-weight-bold text-primary">Documentos</h6>
                </div>
                <div class="col-lg-4 text-right">
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-6">
                  <label>Archivo</label>
                  <input type="file" class="form-control" @change="fileChangeDocument">
                </div>
              </div>
              <div class="row mt-3">
                <div v-for="item in aUsuariosFilesTemp" :key='item' class="col-lg-4">
                  <a style="cursor: pointer" @click="removeItemFiles(item)"><i class="fa fa-remove"></i> </a>&nbsp;
                  <span>{{ item }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-lg-6"></div>
            <div class="col-lg-6 text-right">
              <button @click="cancelarUsuario" class="btn btn-danger">Cancelar</button>&nbsp;
              <button @click="guardarUsuario" class="btn btn-primary">Guardar</button>&nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <notifications position="top right"/>
</template>

<script>
import LeftMenu from "@/components/backoffice/LeftMenu";
import TopMenuBackofficeMain from "@/components/backoffice/TopMenuBackoffice";
import Multiselect from '@vueform/multiselect'
import {useRoute} from "vue-router";

import {notify} from "@kyvg/vue3-notification";

export default {
  name: "NuevoEditarUsuario",
  components: {TopMenuBackofficeMain, LeftMenu, Multiselect},
  data() {
    return {
      usuarioId: null,
      nombre: null,
      isActivo: true,
      nombreUsuario: '',
      apellidosUsuario: '',
      dniUsuario: '',
      direccion: '',
      optionsPais: [],
      optionsProvincia: [],
      optionsCiudad: [],
      optionsTempProvincia: [],
      optionsTempCiudad: [],
      valuePais: '',
      valueProvincia: '',
      valueCiudad: '',
      optionsAgencia: [],
      valueAgencia: '',
      codigoPostal: '',
      codigoUsuario: '',
      nombreUsuarioAcceso: '',
      password: '',
      telefono: '',
      correo: '',
      funcion: '',
      nombreComercial: '',
      oficina: '',
      comisionAgencia: '',
      comisionCaptador: '',
      comision: '',
      nombreContactoMaster: '',
      nombreContactoDelegado: '',
      cursoHerramientas: '',
      aUsuariosFilesTemp: [],
      aUsuariosFiles: [],
      valueTipoUsuario: '',
      optionsTipoUsuario: [
        {value: 'ROLE_SUPER_ADMIN', label: 'Administrador'},
        {value: 'ROLE_MASTER', label: 'Master'},
        {value: 'ROLE_DELEGADO', label: 'Delegado'},
        {value: 'ROLE_AGENCIA', label: 'Agencia'},
        {value: 'ROLES_AGENTE', label: 'Agente'},
        {value: 'ROLE_GERENTE', label: 'Gerente'},
        {value: 'ROLE_CAPTADOR', label: 'Captador'},
        {value: 'ROLE_DRIVER', label: 'Driver'}
      ],
      isVisibleNombreComercial: false,
      isVisibleCodigoUsuario: false,
      isVisibleCuentaBancaria: false,
      isVisibleOficina: false,
      isVisibleAgencia: false,
      isVisibleComisionAgencia: false,
      isVisibleComisionCaptador: false,
      isVisibleComision: false,
      isVisibleDocumentos: false,
      isVisibleNombreContactoMaster: false,
      isVisibleNombreContactoDelegado: false,
      isVisibleCursoHerramientas: false
    }
  },
  mounted() {
    let userLogin = JSON.parse(localStorage.getItem('user'));

    switch (userLogin.roles[0]) {
      case 'ROLES_SUPER_ADMIN':
        this.optionsTipoUsuario = [
          {value: 'ROLES_SUPER_ADMIN', label: 'Administrador'},
          {value: 'ROLES_MASTER', label: 'Master'},
          {value: 'ROLES_DELEGADO', label: 'Delegado'},
          {value: 'ROLES_AGENCIA', label: 'Agencia'},
          {value: 'ROLES_GERENTE', label: 'Gerente'},
          {value: 'ROLES_AGENTE', label: 'Agente'},
          {value: 'ROLES_CAPTADOR', label: 'Captador'},
          {value: 'ROLES_DRIVER', label: 'Driver'}
        ]
        break;
      case 'ROLES_MASTER':
        this.optionsTipoUsuario = [
          {value: 'ROLES_DELEGADO', label: 'Delegado'},
          {value: 'ROLES_AGENCIA', label: 'Agencia'},
        ]
        break;
      case 'ROLES_DELEGADO':
        this.optionsTipoUsuario = [
          {value: 'ROLES_AGENCIA', label: 'Agencia'},
        ]
        break;
      case 'ROLES_AGENCIA':
        this.optionsTipoUsuario = [
          {value: 'ROLES_AGENTE', label: 'Agente'},
        ]
        break;
    }

    const route = useRoute()
    let myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };


    fetch(this.urlDomain + 'get-filtros-localidad', requestOptions).then(async response => {
      let data = await response.json();
      data.aPaises.forEach((obj) => {
        let tipoP = {
          value: obj.id,
          label: obj.nombre
        }
        this.optionsPais.push(tipoP);
      });

      data.aProvincias.forEach((objProvincia) => {
        let provincia = {
          value: objProvincia.id,
          label: objProvincia.nombre,
          pais_id: objProvincia.pais_id
        }
        this.optionsTempProvincia.push(provincia);
      });

      data.aCiudades.forEach((objCiudad) => {
        let ciudad = {
          value: objCiudad.id,
          label: objCiudad.nombre,
          provincia_id: objCiudad.provincia_id
        }
        this.optionsTempCiudad.push(ciudad);
      });

      fetch(this.apiURL + 'configuracion/get-listado-agencias', requestOptions).then(async responseAgencia => {
        let dataAgencia = await responseAgencia.json();

        dataAgencia.aAgencias.forEach((objAgencia) => {
          let agencia = {
            value: objAgencia.id,
            label: objAgencia.nombre
          };
          this.optionsAgencia.push(agencia);
        });
      });


      if (typeof route.params.id !== undefined && typeof route.params.id !== 'undefined') {
        this.usuarioId = route.params.id;
        this.getDetalleUsuario(this.usuarioId);
      }

    });

  },
  methods: {
    selectedProvincia(eventValue) {
      this.valuePais = eventValue;
      let aProvincias = this.optionsTempProvincia.filter((x => x.pais_id === eventValue));
      console.log(aProvincias);
      this.optionsProvincia.splice(0);
      aProvincias.forEach((objProvincias) => {
        let provincia = {
          value: objProvincias.value,
          label: objProvincias.label
        };
        this.optionsProvincia.push(provincia);
      });
    },
    selectedCiudad(eventValue) {
      this.valueProvincia = eventValue;
      let aCiudades = this.optionsTempCiudad.filter((x => x.provincia_id === eventValue));
      this.optionsCiudad.splice(0);
      aCiudades.forEach((objCiudades) => {
        let ciudad = {
          value: objCiudades.value,
          label: objCiudades.label
        }
        this.optionsCiudad.push(ciudad);
      })
    },
    cancelarUsuario() {
      window.location.href = '/admin/usuarios/listado';
    },
    guardarUsuario() {
      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      let formData = new FormData();
      formData.append('nombre', this.nombreUsuario);
      formData.append('apellidos', this.apellidosUsuario);
      formData.append('dni', this.dniUsuario);
      formData.append('direccion', this.direccion);
      formData.append('pais', this.valuePais);
      formData.append('provincia', this.valueProvincia);
      formData.append('ciudad', this.valueCiudad);
      formData.append('codigoPostal', this.codigoPostal);
      formData.append('codigoUsuario', this.codigoUsuario);
      formData.append('nombreUsuario', this.nombreUsuario);
      formData.append('password', this.password);
      formData.append('telefono', this.telefono);
      formData.append('correo', this.correo);
      formData.append('funcion', this.funcion);
      formData.append('nombreComercial', this.nombreComercial);
      formData.append('oficina', this.oficina);
      formData.append('agencia', this.valueAgencia);
      formData.append('comisionAgencia', this.comisionAgencia);
      formData.append('comisionCaptador', this.comisionCaptador);
      formData.append('comision', this.comision);
      formData.append('nombreContactoMaster', this.nombreContactoMaster);
      formData.append('nombreContactoDelegado', this.nombreContactoDelegado);
      formData.append('cursoHerramientas', this.cursoHerramientas);
      formData.append('tipoUsuario', this.valueTipoUsuario);
      this.aUsuariosFiles.forEach((obj) => {
        formData.append('documentos[]', obj);
      });

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData
      };
      let url = (typeof this.usuarioId !== undefined && typeof this.usuarioId !== 'undefined' && this.usuarioId !== null)
          ? 'usuarios/add-editar-usuario' : 'usuarios/add-editar-usuario/' + this.usuarioId;
      fetch(this.apiURL + url, requestOptions).then(async response => {
        const data = await response.json();
        var type = (data.status) ? 'success' : 'error';
        notify({
          title: data.message,
          type: type
        });
        setTimeout(function () {
          window.location.href = '/admin/usuarios/listado';
        }, 3000);
      });
    },
    fileChangeDocument(e) {
      console.log(e.target.files[0]);
      let archivo = e.target.files[0];
      this.aUsuariosFiles.push(archivo);
      this.aUsuariosFilesTemp.push(archivo.name);
      console.log(this.aUsuariosFilesTemp);
      e.target.value = '';
    },
    removeItemFiles(item) {
      let indexFile = this.aUsuariosFiles.findIndex((x => x.name === item));
      this.aUsuariosFiles.splice(indexFile, 1);
      this.aUsuariosFilesTemp.splice(item, 1);
    },
    getDetalleUsuario(id) {
      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
      fetch(this.apiURL + 'usuarios/detalle-usuario/' + id, requestOptions).then(async response => {
        let userPromocion = await response.json();
        this.nombreUsuario = userPromocion.aUsuario.nombre;
        this.apellidosUsuario = userPromocion.aUsuario.apellidos;
        this.dniUsuario = userPromocion.aUsuario.dni;
        this.direccion = userPromocion.aUsuario.direccion;
        this.valuePais = userPromocion.aUsuario.pais;
        this.valueProvincia = userPromocion.aUsuario.provincia;
        this.valueCiudad = userPromocion.aUsuario.ciudad;
        this.codigoPostal = userPromocion.aUsuario.codigoPostal;
        this.codigoUsuario = userPromocion.aUsuario.codigoUsuario;
        this.nombreUsuario = userPromocion.aUsuario.nombreUsuario;
        this.telefono = userPromocion.aUsuario.telefono;
        this.correo = userPromocion.aUsuario.correo;
        this.funcion = userPromocion.aUsuario.funcion;
        this.nombreComercial = userPromocion.aUsuario.nombreComercial;
        this.oficina = userPromocion.aUsuario.oficina;
        this.agencia = userPromocion.aUsuario.agencia;
        this.comisionAgencia = userPromocion.aUsuario.comisionAgencia;
        this.comisionCaptador = userPromocion.aUsuario.comisionCaptador;
        this.comision = userPromocion.aUsuario.comision;
        this.nombreContactoMaster = userPromocion.aUsuario.nombreContactoMaster;
        this.nombreContactoDelegado = userPromocion.aUsuario.nombreContactoDelegado;
        this.cursoHerramientas = userPromocion.aUsuario.cursoHerramientas;
        this.valueTipoUsuario = userPromocion.aUsuario.tipoUsuario;

        userPromocion.aUsuario.docs.forEach((objDoc) => {
          this.aUsuariosFiles.push(objDoc.nombre);
        });
      });
    },
    selectedTipoUsuario() {
      let tipoUsuario = this.valueTipoUsuario;
      this.isVisibleNombreComercial = false;
      this.isVisibleCodigoUsuario = false;
      this.isVisibleCuentaBancaria = false;
      this.isVisibleOficina = false;
      this.isVisibleAgencia = false;
      this.isVisibleComisionAgencia = false;
      this.isVisibleComisionCaptador = false;
      this.isVisibleComision = false;
      this.isVisibleDocumentos = false;
      this.isVisibleNombreContactoMaster = false;
      this.isVisibleNombreContactoDelegado = false;
      this.isVisibleCursoHerramientas = false;

      switch (tipoUsuario) {
        case 'ROLES_MASTER':
          this.isVisibleComision = true;
          this.isVisibleDocumentos = true;
          this.isVisibleCuentaBancaria = true;
          break;
        case 'ROLES_DELEGADO':
          this.isVisibleCodigoUsuario = true;
          this.isVisibleCuentaBancaria = true;
          this.isVisibleComision = true;
          this.isVisibleNombreContactoMaster = true;
          this.isVisibleDocumentos = true;
          break;
        case 'ROLES_AGENCIA':
          this.isVisibleNombreComercial = true;
          this.isVisibleCuentaBancaria = true;
          this.isVisibleComisionAgencia = true;
          this.isVisibleComisionCaptador = true;
          this.isVisibleDocumentos = true;
          this.isVisibleNombreContactoMaster = true;
          this.isVisibleNombreContactoDelegado = true;
          this.isVisibleCursoHerramientas = true;
          break;
        case 'ROLES_GERENTE':

          break;
        case 'ROLES_AGENTE':
          this.isVisibleOficina = true;
          this.isVisibleAgencia = true;
          this.isVisibleCursoHerramientas = true;
          break;
        case 'ROLES_CAPTADOR':
          this.isVisibleCuentaBancaria = true;
          this.isVisibleComisionAgencia = true;
          this.isVisibleComisionCaptador = true;
          this.isVisibleDocumentos = true;
          break;
        case 'ROLES_DRIVER':
          this.isVisibleCuentaBancaria = true;
          this.isVisibleComision = true;
          break;
      }

    }
  }
}
</script>

<style scoped>

</style>
