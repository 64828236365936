<template>
  <div id="wrapper">
    <LeftMenu></LeftMenu>
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <TopMenuBackofficeMain></TopMenuBackofficeMain>
        <div class="container-fluid">
          <h1 class="h3 mb-2 text-gray-800 mb-4">Listado usuario</h1>
          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-lg-8">
                  <h6 class="m-0 font-weight-bold text-primary">Listado usuarios</h6>
                </div>
                <div class="col-lg-4 text-right">
                  <button @click="nuevoUsuario" class="btn btn-primary"><i class="fa-solid fa-plus"></i>
                    Añadir
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <TableListado :fields='fields' :tableData="usuariosData"></TableListado>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from "@/components/backoffice/LeftMenu";
import TopMenuBackofficeMain from "@/components/backoffice/TopMenuBackoffice";
import TableListado from "@/components/backoffice/TableListado";

export default {
  name: "ListadoUsuarios",
  components: {TableListado, TopMenuBackofficeMain, LeftMenu},
  data() {
    return {
      fields: ['ID', 'Nombre y Apellidos', 'DNI', 'Codigo usuario', 'Agencia', 'Acciones'],
      usuariosData: [],
    }
  },
  mounted() {

    let url = 'usuarios/get-listado-usuarios';
    let myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    fetch(this.apiURL + url, requestOptions).then(async response => {
      const data = await response.json();
      data.aUsuarios.forEach((obj) => {
        let usuario = {
          ID: obj.id,
          'Nombre y Apellidos': obj.nombre_apellidos,
          DNI: obj.dni,
          'Codigo usuario': obj.estado,
          Agencia: obj.agencia,
          Acciones: '<a href="/admin/usuarios/editar-usuario/' + obj.id + '"><i class="fa fa-pencil"></i></a>&nbsp;' +
              '<a data-id="' + obj.id + '" class="deleteUsuarios" style="cursor: pointer"><i class="fa fa-trash"></i> </a>'
        }
        this.usuariosData.push(usuario);
      })
    })

  },
  methods: {
    nuevoUsuario() {
      window.location.href = '/admin/usuarios/nuevo-usuario';
    }
  }
}
</script>

<style scoped>

</style>
