<template>
  <h3 class="text-center titleColor">{{ $t("promotions.title") }}</h3>
  <div class="row mt-4 justify-content-center" v-if="aPromociones.length >= 1">
    <div class="col-lg-2 col-md-2 col-xs-12 col-sm-12 mb-2" v-for="promocion in aPromociones" :key="promocion">
      <div class="card">
        <img :src="promocion.fotoPrincipal" class="card-img-top img-fluid" style="height: 230px;">
        <div class="card-body">
          <h5 class="card-title">{{ promocion.nombre }}</h5>
          <p class="card-text">{{ promocion.descripcion }}</p>
          <a :href="'/detalle-promocion/'+promocion.id" class="btn btn-primary">Detalle promoción</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PromotionsElement",
  data() {
    return {
      aPromociones: []
    }
  },
  mounted() {
    this.getPromocionesDestacadas()
  },
  methods: {
    getPromocionesDestacadas() {
      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      const requestOptions = {
        method: 'GET',
        //   headers: myHeaders,
      };


      fetch(this.urlDomain + 'promociones/get-promociones-destacadas', requestOptions).then(async response => {
        const data = await response.json();
        data.aPromociones.forEach((objPromocion) => {
          let oPromociones = {
            id: objPromocion.id,
            fotoPrincipal: this.urlDomain + '/' + objPromocion.fotoPrincipal,
            titulo: objPromocion.nombre,
            descripcion: objPromocion.descripcion
          }
          this.aPromociones.push(oPromociones);
        });

      });
    }
  }
}
</script>

<style scoped>

</style>
