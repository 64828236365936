import {createApp} from 'vue'
import App from './App.vue'
import router from "../router";
/*import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";*/
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import i18n from './i18n'
import './assets/css/main.css'
import './assets/css/backoffice.css'
import Select2 from 'vue3-select2-component';

require('@/assets/js/backoffice')
import axios from 'axios'
import VueAxios from 'vue-axios'
import Notifications from '@kyvg/vue3-notification'
/*https://blog.flycode.com/step-by-step-how-to-create-a-vue-multi-language-app-with-vue-i18n*/

let app = createApp(App)

app.config.globalProperties.apiURL = 'https://apihousein.appsamedida.es/api/';
app.config.globalProperties.urlDomain = 'https://apihousein.appsamedida.es/';

app.use(i18n).use(router).use(VueAxios, axios).use(Notifications).component('Select2', Select2).mount('#app')
