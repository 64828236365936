<template>
  <div id="wrapper">
    <LeftMenu></LeftMenu>
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <TopMenuBackofficeMain></TopMenuBackofficeMain>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from "@/components/backoffice/LeftMenu";
import TopMenuBackofficeMain from "@/components/backoffice/TopMenuBackoffice";

export default {
  name: "DashboardMain",
  components: {TopMenuBackofficeMain, LeftMenu}
}
</script>

<style scoped>

</style>
