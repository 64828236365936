<template>
  <div id="wrapper">
    <LeftMenu></LeftMenu>
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <TopMenuBackofficeMain></TopMenuBackofficeMain>
        <div class="container-fluid">
          <h1 class="h3 mb-2 text-gray-800 mb-4">{{ $t("backoffice.promociones.titleNew") }}</h1>
          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-lg-8">
                  <h6 class="m-0 font-weight-bold text-primary">Datos básicos</h6>
                </div>
                <div class="col-lg-4 text-right">
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Foto principal</label><br>
                    <div id="preview">
                      <img class="img-thumbnail col-3" v-if="url" :src="url"/>
                    </div>
                    <input type="file" name="foto" @change="onFileChange"/>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Nombre</label>
                    <input v-model="nombrePromocion" type="text" class="form-control"/>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Titulo principal</label>
                    <input v-model="tituloPrincipal" type="text" class="form-control">
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Título secundario</label>
                    <input v-model="tituloSecundario" type="text" class="form-control">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Descripción 1</label>
                    <textarea v-model="descripcion1" class="form-control"></textarea>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Descripción 2</label>
                    <textarea v-model="descripcion2" class="form-control"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Tipos</label>
                    <Multiselect mode="tags" :searchable="true"
                                 v-model="valueTipos"
                                 :options="options"
                    />
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Dormitorios</label>
                    <Multiselect mode="tags" :searchable="true"
                                 v-model="valueDormitorios"
                                 :options="optionsDormitorios"
                    />
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Baños</label>
                    <Multiselect mode="tags" :searchable="true"
                                 v-model="valueBanios"
                                 :options="optionsBanios"/>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <div class="custom-control custom-checkbox">
                      <input v-model="isPromocionDestacada" type="checkbox" class="custom-control-input"
                             id="isDestacada">
                      <label class="custom-control-label" for="isDestacada">Promoción destacada</label>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Fecha alta</label>
                    <input type="date" disabled class="form-control" v-model="fechaAlta">
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Autor promoción</label>
                    <input disabled type="text" class="form-control" v-model="autorPromocion">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3">
                  <div class="form-group">
                    <label>Captador</label>
                    <Multiselect :disabled="!isAdmin"
                                 v-model="valueCaptador"
                                 :options="listCaptador"/>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="form-group">
                    <label>Agencia</label>
                    <Multiselect
                        v-model="valueAgencia"
                        :options="listAgencia"/>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="form-group">
                    <label>Estado</label>
                    <select disabled class="custom-select" v-model="estado">
                      <option v-for="option in optionsEstados" :key="option.id" :value="option.id">{{
                          option.value
                        }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-lg-8">
                  <h6 class="m-0 font-weight-bold text-primary">Visitas</h6>
                </div>
                <div class="col-lg-4 text-right">
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Días de visita</label>
                    <Multiselect
                        v-model="valueDiasVisita"
                        :options="optionsDiasVisita"/>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label>Horario de visita desde</label>
                    <div class="input-group">
                      <select class="custom-select" v-model="horaVisita">
                        <option v-for="item in aHoras" :key="item" :value="item">{{ item }}</option>
                      </select>
                      <div class="input-group-append">
                        <select class="custom-select" v-model="minutoVisita">
                          <option value="00">00</option>
                          <option value="30">30</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <label>Horario de visita hasta</label>
                    <div class="input-group">
                      <select class="custom-select" v-model="horaVisitaHasta">
                        <option v-for="item in aHoras" :key="item" :value="item">{{ item }}</option>
                      </select>
                      <div class="input-group-append">
                        <select class="custom-select" v-model="minutoVisitaHasta">
                          <option value="00">00</option>
                          <option value="30">30</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2" style="margin-top:33px;">
                  <button class="btn btn-primary" @click="addVisita">Añadir</button>
                </div>
              </div>
              <div class="row">
                <div v-for="item in aListadoVisitas" :key='item' class="col-lg-4">
                  <a style="cursor: pointer" @click="removeItemVisitas(item)"><i class="fa fa-remove"></i> </a>&nbsp;
                  <span>{{ item }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-lg-8">
                  <h6 class="m-0 font-weight-bold text-primary">Ubicación</h6>
                </div>
                <div class="col-lg-4 text-right">
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-6"></div>
                <div class="col-lg-6">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>Dirección</label>
                        <input v-model="dirrecion" type="text" class="form-control"/>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label>Código postal</label>
                        <input v-model="codigoPostal" type="text" class="form-control">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="form-group">
                        <label>País</label>
                        <Multiselect @change="selectedProvincia($event)"
                                     v-model="valuePais"
                                     :options="optionsPais"/>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group">
                        <label>Provincia</label>
                        <Multiselect @change="selectedCiudad($event)" :searchable="true"
                                     v-model="valueProvincia"
                                     :options="optionsProvincia"/>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group">
                        <label>Ciudad</label>
                        <Multiselect
                            :searchable="true"
                            v-model="valueCiudad"
                            :options="optionsCiudad"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-lg-8">
                  <h6 class="m-0 font-weight-bold text-primary">Media</h6>
                </div>
                <div class="col-lg-4 text-right">
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Vídeo</label>
                    <img :src="imageVideo" class="img-thumbnail" v-if="isVideoUpload"/><br>
                    <input type="file" @change="saveFileVideo">
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Live CAM</label>
                    <input v-model="liveCAM" type="text" class="form-control">
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label>Vídeo 360º</label>
                    <input type="text" v-model="video360" class="form-control">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Carousel </label>
                    <div class="row">
                      <div class="col-lg-3">
                        <label>Imágen 1</label>
                        <input type="file" @change="saveImageCarousel">
                      </div>
                      <div class="col-lg-3">
                        <label>Imágen 2</label>
                        <input type="file" @change="saveImageCarousel">
                      </div>
                      <div class="col-lg-3">
                        <label>Imágen 3</label>
                        <input type="file" @change="saveImageCarousel">
                      </div>
                      <div class="col-lg-3">
                        <label>Imágen 4</label>
                        <input type="file" @change="saveImageCarousel">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-lg-8">
                  <h6 class="m-0 font-weight-bold text-primary">Opciones generales</h6>
                </div>
                <div class="col-lg-4 text-right">
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Opciones adicionales</label>
                    <Multiselect
                        v-model="valueAdiciones"
                        :options="optionsAdicionales"
                    />
                  </div>
                </div>
                <div class="col-lg-2" style="margin-top:33px;">
                  <button @click="saveOpcionesListado" class="btn btn-primary">Añadir</button>
                </div>
              </div>
              <div class="row">
                <div v-for="item in listadoOpciones" :key='item' class="col-lg-4">
                  <a style="cursor: pointer" @click="removeItemOpciones(item)"><i class="fa fa-remove"></i> </a>&nbsp;
                  <span>{{ item }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-lg-8">
                  <h6 class="m-0 font-weight-bold text-primary">Documentos</h6>
                </div>
                <div class="col-lg-4 text-right">
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-6">
                  <label>Archivo</label>
                  <input type="file" class="form-control" @change="fileChangeDocument">
                </div>
              </div>
              <div class="row mt-3">
                <div v-for="item in aFilesPromocionesTemp" :key='item' class="col-lg-4">
                  <a style="cursor: pointer" @click="removeItemPromociones(item)"><i class="fa fa-remove"></i> </a>&nbsp;
                  <span>{{ item }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-lg-8">
                  <h6 class="m-0 font-weight-bold text-primary">Comisión de Venta</h6>
                </div>
                <div class="col-lg-4 text-right">
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4">
                  <label>Comisión</label>
                  <div class="input-group">
                    <input type="number" class="form-control" v-model="comision">
                    <div class="input-group-append">
                      <label class="input-group-text">%</label>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <label>Condiciones de pago de la comisión</label>
                  <input v-model="condicionesComision" type="text" class="form-control"/>
                </div>
                <div class="col-lg-4">
                  <label>Entrega</label>
                  <input v-model="entrega" type="date" class="form-control">
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-lg-6"></div>
            <div class="col-lg-6 text-right">
              <button @click="cancelarPromocion" class="btn btn-danger">Cancelar</button>&nbsp;
              <button @click="guardarPromocion" class="btn btn-primary">Guardar</button>&nbsp;
              <button @click="publicarPromocion" class="btn btn-success">Publicar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <notifications position="top right"/>
</template>

<script>
import LeftMenu from "@/components/backoffice/LeftMenu";
import TopMenuBackofficeMain from "@/components/backoffice/TopMenuBackoffice";
import Multiselect from '@vueform/multiselect'
import {notify} from "@kyvg/vue3-notification";
import {useRoute} from "vue-router";
/*https://github.com/vueform/multiselect#using-with-vue-3*/
export default {
  name: "NuevaPromocion",
  components: {TopMenuBackofficeMain, LeftMenu, Multiselect},
  data() {
    return {
      aHoras: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
      promocionId: null,
      nombrePromocion: null,
      tituloPrincipal: null,
      tituloSecundario: null,
      descripcion1: null,
      descripcion2: null,
      isPromocionDestacada: false,
      url: null,
      valueTipos: [],
      horaVisita: null,
      minutoVisita: null,
      horaVisitaHasta: null,
      minutoVisitaHasta: null,
      fechaAlta: new Date().toISOString().slice(0, 10),
      options: [],
      valueDormitorios: [],
      optionsDormitorios: [],
      valueBanios: [],
      optionsBanios: [],
      valueAdiciones: null,
      optionsAdicionales: [],
      listadoOpciones: [],
      valueDiasVisita: null,
      optionsDiasVisita: [
        {value: 1, label: 'Lunes-Viernes'},
        {value: 2, label: 'Todos los días'},
        {value: 3, label: 'Fin de semana'},
        {value: 4, label: 'Otros'}
      ],
      optionsEstados: [
        {id: 1, value: 'Borrador'},
        {id: 2, value: 'Pendiente'},
        {id: 3, value: 'Activo'}
      ],
      valuePais: [],
      optionsPais: [],
      valueProvincia: [],
      optionsProvincia: [],
      valueCiudad: [],
      optionsCiudad: [],
      aTempOptionsProvincia: [],
      aTempsOptionsCiudad: [],
      autorPromocion: null,
      estado: 1,
      uploadFileTemp: null,
      aFilesPromociones: [],
      aFilesPromocionesTemp: [],
      aVisitas: [],
      aCarousel: [],
      dirrecion: null,
      codigoPostal: null,
      liveCAM: null,
      comision: null,
      condicionesComision: null,
      entrega: null,
      video: null,
      video360: null,
      fotoPrincipal: null,
      listCaptador: [],
      valueCaptador: [],
      listAgencia: [],
      valueAgencia: [],
      isAdmin: false,
      isVideoUpload: false,
      imageVideo: '',
      video360Upload: '',
      isVideo360Upload: false,
      aListadoVisitas: []
    }
  },
  mounted() {
    const route = useRoute()
    let myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };


    fetch(this.apiURL + 'promociones/get-dropdowns', requestOptions).then(async response => {
      let data = await response.json();
      console.log(data);
      data.aTipos.forEach((obj) => {
        let tipoO = {
          value: obj.id,
          label: obj.nombre
        }
        this.options.push(tipoO);
      });

      data.aDormitorios.forEach((objD) => {
        let dormi = {
          value: objD.id,
          label: objD.nombre
        }
        this.optionsDormitorios.push(dormi);
      });

      data.aBanios.forEach((objB) => {
        let banios = {
          value: objB.id,
          label: objB.nombre
        }
        this.optionsBanios.push(banios);
      })

      data.aPaises.forEach((objPais) => {
        let pais = {
          value: objPais.id,
          label: objPais.nombre
        }
        this.optionsPais.push(pais);
      })

      data.aProvincias.forEach((objProvincia) => {
        let provincia = {
          value: objProvincia.id,
          label: objProvincia.nombre,
          pais_id: objProvincia.pais_id
        }
        this.aTempOptionsProvincia.push(provincia);
      })

      data.aCiudades.forEach((objCiudad) => {
        let ciudad = {
          value: objCiudad.id,
          label: objCiudad.nombre,
          provincia_id: objCiudad.provincia_id
        }
        this.aTempsOptionsCiudad.push(ciudad);
      });

      data.aOpciones.forEach((objTipo) => {
        let tipo = {
          value: objTipo.id,
          label: objTipo.nombre
        }
        this.optionsAdicionales.push(tipo);
      })

      data.aAgencias.forEach((objAgencia) => {
        let agencia = {
          value: objAgencia.id,
          label: objAgencia.nombre
        }
        this.listAgencia.push(agencia);
      })


      //Obtenemos el id de promocion

      if (typeof route.params.id !== undefined && typeof route.params.id !== 'undefined') {
        console.log(typeof route.params.id);
        this.promocionId = route.params.id;
        fetch(this.apiURL + 'promociones/get-detalle-promocion/' + this.promocionId, requestOptions).then(async response => {
          let dataPromocion = await response.json();
          console.log(dataPromocion);
          this.url = dataPromocion.aPromocion.fotoPrincipal;
          this.nombrePromocion = dataPromocion.aPromocion.nombre;
          this.tituloPrincipal = dataPromocion.aPromocion.tituloPrincipal;
          this.tituloSecundario = dataPromocion.aPromocion.tituloSecundario;
          this.descripcion1 = dataPromocion.aPromocion.descripcion1;
          this.descripcion2 = dataPromocion.aPromocion.descripcion2;
          dataPromocion.aPromocion.aTipos.forEach((objTipo) => {
            this.valueTipos.push(objTipo.tipo_id);
          });
          dataPromocion.aPromocion.aDormitorios.forEach((objDormitorio) => {
            this.valueDormitorios.push(objDormitorio.dormitorio_id);
          });
          dataPromocion.aPromocion.aBanios.forEach((objBanio) => {
            this.valueBanios.push(objBanio.banio_id);
          })
          this.isPromocionDestacada = dataPromocion.aPromocion.isPromocionDestacada;
          this.dirrecion = dataPromocion.aPromocion.direccion;
          this.codigoPostal = dataPromocion.aPromocion.codigoPostal;
          this.valuePais.push(dataPromocion.aPromocion.pais);
          this.selectedProvincia(dataPromocion.aPromocion.pais);
          this.valueProvincia.push(dataPromocion.aPromocion.provincia);
          this.selectedCiudad(dataPromocion.aPromocion.provincia);
          this.valueCiudad.push(dataPromocion.aPromocion.ciudad);
          this.liveCAM = (dataPromocion.aPromocion.liveCam !== 'null') ? dataPromocion.aPromocion.liveCam : '';
          this.isVideoUpload = (dataPromocion.aPromocion.video !== 'null' && dataPromocion.aPromocion.video !== '');
          this.imageVideo = (dataPromocion.aPromocion.video !== 'null') ? dataPromocion.aPromocion.video : '';
          this.isVideo360Upload = (dataPromocion.aPromocion.video360 !== 'null' && dataPromocion.aPromocion.video360 !== '');
          // this.video360Upload = (dataPromocion.aPromocion.video360 !== 'null') ? dataPromocion.aPromocion.video360 : '';
          dataPromocion.aPromocion.aOpciones.forEach((objOpciones) => {
            this.listadoOpciones.push(objOpciones.nombre);
          });
          dataPromocion.aPromocion.aDocumentos.forEach((objDocumentos) => {
            this.aFilesPromocionesTemp.push(objDocumentos.nombre);
          })
          dataPromocion.aPromocion.aVisitas.forEach((objVisitas) => {
            this.aListadoVisitas.push(objVisitas.descripcion);
          });
          this.comision = dataPromocion.aPromocion.comision;
          this.condicionesComision = dataPromocion.aPromocion.condiciones;
          this.entrega = dataPromocion.aPromocion.entrega;
          this.valueCaptador.push(dataPromocion.aPromocion.captador);
          this.valueAgencia.push(dataPromocion.aPromocion.agencia);
        });
      }

    });

    let user = JSON.parse(localStorage.getItem('user'));
    console.log(user);
    if (user.roles[0] === 'ROLES_SUPER_ADMIN' || user.roles[0] === 'ROLE_ADMIN') {
      this.isAdmin = true;
    } else if (user.roles[0] === 'ROLE_AGENTE') {
      this.isAdmin = true;
      //Cargamos el listado de usuarios de esa agencia
    }
    this.autorPromocion = user.nombre + " " + user.apellidos;


  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      this.fotoPrincipal = file;
      this.url = URL.createObjectURL(file);
    },
    addVisita() {
      let valorVisita = this.optionsDiasVisita.filter((x => x.value === this.valueDiasVisita))[0].label;
      this.aListadoVisitas.push(valorVisita + " " + this.horaVisita + ":" + this.minutoVisita + " - " + this.horaVisitaHasta + ":" + this.minutoVisitaHasta);
    },
    saveOpcionesListado() {
      const optionsValue = this.optionsAdicionales.filter((x => x.value === this.valueAdiciones))[0].label;
      this.listadoOpciones.push(optionsValue);
      this.valueAdiciones = null;
    },
    removeItemOpciones(item) {
      console.log(item);
      let indexOpciones = this.listadoOpciones.findIndex((x => x === item));
      this.listadoOpciones.splice(indexOpciones, 1);
    },
    guardarPromocion() {
      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      let formData = new FormData();
      formData.append('fotoPrincipal', this.fotoPrincipal);
      formData.append('nombre', this.nombrePromocion);
      formData.append('tituloPrincipal', this.tituloPrincipal);
      formData.append('tituloSecundario', this.tituloSecundario);
      formData.append('descripcion1', this.descripcion1);
      formData.append('descripcion2', this.descripcion2);
      formData.append('tipos', this.valueTipos);
      formData.append('dormitorios', this.valueDormitorios);
      formData.append('banios', this.valueBanios);
      formData.append('isPromocionDestacada', this.isPromocionDestacada);
      formData.append('direccion', this.dirrecion);
      formData.append('codigoPostal', this.codigoPostal);
      formData.append('pais', this.valuePais);
      formData.append('provincia', this.valueProvincia);
      formData.append('ciudad', this.valueCiudad);
      formData.append('video', this.video);
      formData.append('liveCam', this.liveCAM);
      formData.append('video360', this.video360);
      //formData.append('carousel', this.aCarousel);
      this.aCarousel.forEach((obj) => {
        formData.append('carousel[]', obj);
      })
      formData.append('opcionesGenerales', this.listadoOpciones);
      //formData.append('documentos', this.aFilesPromociones);
      this.aFilesPromociones.forEach((obj) => {
        formData.append('documentos[]', obj);
      })
      formData.append('comision', this.comision);
      formData.append('condiciones', this.condicionesComision);
      formData.append('entrega', this.entrega);
      formData.append('captador', this.valueCaptador);
      formData.append('agencia', this.valueAgencia);
      formData.append('estado', this.estado);
      formData.append('visitas', this.aListadoVisitas);
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData
      };
      let url = (typeof this.promocionId !== undefined && typeof this.promocionId !== 'undefined' && this.promocionId !== null)
          ? 'promociones/add-editar-promocion/' + this.promocionId : 'promociones/add-editar-promocion';
      fetch(this.apiURL + url, requestOptions).then(async response => {
        const data = await response.json();
        console.log(data);
        var type = (data.status) ? 'success' : 'error';
        notify({
          title: data.message,
          type: type
        })
        setTimeout(function(){
          window.location.href = '/admin/configuracion/listado-agencias';
        },3000);
      });
    },
    publicarPromocion() {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user.roles[0] === 'ROLES_SUPER_ADMIN' || user.roles[0] === 'ROLE_ADMIN') {
        this.estado = 3;
      } else if (user.roles[0] === 'ROLE_AGENTE') {
        this.estado = 2;
      }
      let fieldsError = this.validateFilds()
      if (!fieldsError) {
        this.guardarPromocion()
      }

    },
    validateFilds() {
      let isError = false;
      if (this.nombrePromocion === null) {
        notify({
          title: 'No ha introducido el nombre de la promoción',
          type: 'error'
        });
        isError = true;
      }
      if (this.tituloPrincipal === null) {
        notify({
          title: 'No ha introducido el título principal',
          type: 'error'
        });
        isError = true;
      }
      if (this.descripcion1 === null) {
        notify({
          title: 'No ha introducido la descripción 1',
          type: 'error'
        });
        isError = true;
      }
      if (this.fotoPrincipal === null && this.url === null) {
        notify({
          title: 'No ha introducido la foto principal',
          type: 'error'
        });
        isError = true;
      }
      if (this.valueDormitorios.length === 0) {
        notify({
          title: 'No ha indicado ningún dormitorio',
          type: 'error'
        });
        isError = true;
      }
      if (this.valueTipos.length === 0) {
        notify({
          title: 'No ha indicado ningún tipo de vivienda',
          type: 'error'
        })
        isError = true;
      }
      if (this.valueBanios.length === 0) {
        notify({
          title: 'No ha indicado el número de baños',
          type: 'error'
        })
        isError = true;
      }

      return isError;
    },
    cancelarPromocion() {
      window.location.href = '/admin/promociones';
    },
    selectedProvincia(eventValue) {
      this.valuePais = eventValue;
      let aProvincias = this.aTempOptionsProvincia.filter((x => x.pais_id === eventValue));
      console.log(aProvincias);
      this.optionsProvincia.splice(0);
      aProvincias.forEach((objProvincias) => {
        let provincia = {
          value: objProvincias.value,
          label: objProvincias.label
        };
        this.optionsProvincia.push(provincia);
      });
    },
    selectedCiudad(eventValue) {
      this.valueProvincia = eventValue;
      let aCiudades = this.aTempsOptionsCiudad.filter((x => x.provincia_id === eventValue));
      this.optionsCiudad.splice(0);
      aCiudades.forEach((objCiudades) => {
        let ciudad = {
          value: objCiudades.value,
          label: objCiudades.label
        }
        this.optionsCiudad.push(ciudad);
      })
    },
    removeItemPromociones(item) {
      let indexFile = this.aFilesPromociones.findIndex((x => x.name === item));
      this.aFilesPromociones.splice(indexFile, 1);
      this.aFilesPromocionesTemp.splice(item, 1);
    },
    removeItemVisitas(item) {
      let index = this.aListadoVisitas.findIndex((x => x === item));
      this.aListadoVisitas.splice(index, 1);
    },
    fileChangeDocument(e) {
      console.log(e.target.files[0]);
      let archivo = e.target.files[0];
      this.aFilesPromociones.push(archivo);
      this.aFilesPromocionesTemp.push(archivo.name);
      console.log(this.aFilesPromocionesTemp);
      e.target.value = '';
    },
    saveImageCarousel(e) {
      this.aCarousel.push(e.target.files[0]);
    },
    saveFileVideo(e) {
      this.video = e.target.files[0];
    },
    /*saveVideo360(e) {
      this.video360 = e.target.files[0];
    }*/
  }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>

</style>
