<template>
  <div id="sliderPromocion" class="carousel slide">
    <div class="carousel-inner" v-for="(item, index) in aImagenes" :key="index">
      <div class="carousel-item" :class="(index === 0) ?'active': ''">
        <img :src="item.ruta" class="d-block w-100 fill">
      </div>
      <!--<div class="carousel-item">
        <img src="" class="d-block w-100" alt="...">
      </div>-->
    </div>
    <a class="carousel-control-prev" role="button" data-bs-target="#sliderPromocion"
       data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" data-bs-target="#sliderPromocion"
       data-bs-slide="next" role="button">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>

  </div>
</template>
<style>
.carousel-item {
  display: block;
  width: 100%;
  height: 900px;
}
.carousel-item img {
  height: 100%;
  width: 100%;
}
</style>
<script>
import * as Bootstrap from 'bootstrap'

export default {
  props: ["aImagenes"],
  name: "CarouselPromocion",
  mounted() {
    var myCarousel = document.querySelector("#sliderPromocion")
    new Bootstrap.Carousel(myCarousel)
  }
}
</script>

<style scoped>

</style>
