<template>
  <h3 class="text-center titleColor">{{ $t("filter.title") }}</h3>
  <div class="row mt-3 justify-content-center">
    <div class="col-lg-2 col-md-2 col-xs-12 col-sm-12 mb-2">
      <div class="form-group">
        <label>Lugar</label>
        <Multiselect :searchable="true"
                     v-model="valueLugares"
                     :options="aLugares" @change="removeFilterLugar"
        />
      </div>
    </div>
    <div class="col-lg-2 col-md-2 col-xs-12 col-sm-12 mb-2">
      <div class="form-group">
        <label>Tipo</label>
        <Multiselect :searchable="true"
                     v-model="valueTipo"
                     :options="aTipos" @change="removeTipoFilter"
        />
      </div>
    </div>
    <div class="col-lg-2 col-md-2 col-xs-12 col-sm-12 mb-2">
      <div class="form-group">
        <label>Dormitorios</label>
        <Multiselect :searchable="true"
                     v-model="valueDormitorios"
                     :options="aDormitorios" @change="removeDormitoriosFilter"
        />
      </div>
    </div>
    <div class="col-lg-2 col-md-2 col-xs-12 col-sm-12 mb-2">
      <div class="form-group">
        <label>Precios</label>
        <Multiselect :searchable="true"
                     v-model="valuePrecio"
                     :options="aPrecios" @change="removePriceFilter"
        />
      </div>
    </div>
    <div class="col-lg-2 col-md-2 col-xs-12 col-sm-12 mb-2 mt-4 text-center">
      <button @click="filterPromociones" class="btn btn-primary btnCoorporativo">{{ $t("filter.buscar") }}</button>
    </div>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'

export default {
  name: "FilterElement",
  components: {Multiselect},
  data() {
    return {
      aLugares: [],
      aTipos: [],
      aDormitorios: [],
      aPrecios: [
        {value: '100000', label: 'hasta 100.000€'},
        {value: '101000_200000', label: 'de 101.000€ a 200.000€'},
        {value: '201000_400000', label: 'de 201.000€ a 400.000€'},
        {value: '401000_600000', label: 'de 401.000€ a 600.000€'},
        {value: 'g_600000', label: 'más de 600.000€'}
      ],
      valueLugares: '',
      valueTipo: '',
      valueDormitorios: '',
      valuePrecio: '',
    }
  },
  mounted() {
    this.getFiltrados()
  },
  methods: {
    getFiltrados() {
      let myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };


      fetch(this.urlDomain + 'get-filtros-front', requestOptions).then(async response => {
        const data = await response.json();
        console.log(data);
        data.aProvincias.forEach((objProvincias) => {
          let aProvincia = {
            value: objProvincias.id,
            label: objProvincias.nombre
          };
          this.aLugares.push(aProvincia);
        })
        data.aTipos.forEach((objTipo) => {
          let aTipos = {
            value: objTipo.id,
            label: objTipo.nombre
          }
          this.aTipos.push(aTipos);
        })
        data.aDormitorios.forEach((objDormitorio) => {
          let aDormitorio = {
            value: objDormitorio.id,
            label: objDormitorio.nombre
          }
          this.aDormitorios.push(aDormitorio);
        })

        if (typeof localStorage.getItem('filterLugar') !== 'undefined') {
          this.valueLugares = localStorage.getItem('filterLugar');
        }

        if (typeof localStorage.getItem('filterTipo') !== 'undefined') {
          this.valueTipo = localStorage.getItem('filterTipo');
        }

        if (typeof localStorage.getItem('filterDormitorio') !== 'undefined') {
          this.valueDormitorios = localStorage.getItem('filterDormitorio');
        }

        if (typeof localStorage.getItem('filterPrecio') !== 'undefined') {
          this.valuePrecio = localStorage.getItem('filterPrecio');
        }

      });


    },
    filterPromociones() {
      if (this.valueLugares !== null) {
        localStorage.setItem('filterLugar', this.valueLugares);
      }
      if (this.valueTipo !== null) {
        localStorage.setItem('filterTipo', this.valueTipo);
      }
      if (this.valueDormitorios !== null) {
        localStorage.setItem('filterDormitorio', this.valueDormitorios);
      }
      console.log(this.valuePrecio);
      if (this.valuePrecio !== null) {
        localStorage.setItem('filterPrecio', this.valuePrecio);
      }
      window.location.href = '/listado-promociones/busqueda';
    },
    removePriceFilter() {
      if (typeof localStorage.getItem('filterPrecio') !== 'undefined') {
        localStorage.removeItem('filterPrecio');

      }
    },
    removeDormitoriosFilter() {
      if (typeof localStorage.getItem('filterDormitorio') !== 'undefined') {
        localStorage.removeItem('filterDormitorio');
      }
    },
    removeTipoFilter() {
      if (typeof localStorage.getItem('filterTipo') !== 'undefined') {
        localStorage.removeItem('filterTipo');
      }
    },
    removeFilterLugar() {
      if (typeof localStorage.getItem('filterLugar') !== 'undefined') {
        localStorage.removeItem('filterLugar');
      }
    }
  }
}
</script>

<style scoped>

</style>
