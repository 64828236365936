<template>
  <TopMenu></TopMenu>
  <div class="container">
    <MenuItemsHome></MenuItemsHome>
  </div>
  <div class="row">
    <div class="col-12">
      <CarouselForm></CarouselForm>
    </div>
  </div>
  <div class="row bg-filter">
    <div class="col-12 mt-3">
      <Filter></Filter>
    </div>
  </div>
  <div class="row bg-filter">
    <div class="col-12 mt-5">
      <Promotions></Promotions>
    </div>
  </div>
</template>

<script>

import TopMenu from "@/components/global/top-menu";
import MenuItemsHome from "@/components/home/MenuItemsHome";
import CarouselForm from "@/components/home/CarouselForm";
import Filter from "@/components/Filter";
import Promotions from "@/components/home/Promotions";

export default {
  name: 'Home-Main',
  components: {Promotions, Filter, MenuItemsHome, TopMenu, CarouselForm},
}
</script>

<style scoped>


</style>
