<template>
  <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

    <!-- Sidebar - Brand -->
    <a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
      <div class="sidebar-brand-icon rotate-n-15">

      </div>
      <div class="sidebar-brand-text mx-3">HouseIn</div>
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0">

    <!-- Nav Item - Dashboard -->
    <li class="nav-item active">
      <a class="nav-link" href="index.html">
        <i class="fas fa-fw fa-tachometer-alt"></i>
        <span class="ml-2">Dashboard</span></a>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider">
    <!-- Heading -->

    <li class="nav-item">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePromociones"
         aria-expanded="true" aria-controls="collapseTwo">
        <i class="fa-solid fa-building"></i>
        <span class="ml-1">{{ $t("backoffice.leftmenu.titlePromociones") }}</span>
      </a>
      <div id="collapsePromociones" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">{{ $t("backoffice.leftmenu.titlePromociones") }}</h6>
          <a class="collapse-item" href="/admin/promociones">{{ $t("backoffice.leftmenu.titleListadoPromocion")}}</a>
          <a class="collapse-item" href="/admin/promociones/nuevo">{{ $t("backoffice.leftmenu.titleNuevaPromocion")}}</a>
          <a class="collapse-item" href="/admin/promociones/1">{{ $t("backoffice.leftmenu.titleBorradorPromociones")}}</a>
          <a class="collapse-item" href="">{{ $t("backoffice.leftmenu.titleBajaPromociones")}}</a>
        </div>
      </div>

    </li>

    <li class="nav-item">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUsuarios"
         aria-expanded="true" aria-controls="collapseTwo">
        <i class="fa-solid fa-user"></i>
        <span class="ml-1">{{ $t("backoffice.leftmenu.titleUser") }}</span>
      </a>
      <div id="collapseUsuarios" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">{{ $t("backoffice.leftmenu.titleUser") }}</h6>
          <a class="collapse-item" href="/admin/usuarios/nuevo-usuario">{{ $t("backoffice.leftmenu.titleNuevoUser")}}</a>
          <a class="collapse-item" href="/admin/usuarios/listado">{{ $t("backoffice.leftmenu.titleListadoUser")}}</a>
          <a class="collapse-item" href="">{{ $t("backoffice.leftmenu.titleBajaUser")}}</a>
        </div>
      </div>

    </li>

    <!-- Nav Item - Pages Collapse Menu -->
    <li class="nav-item">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo"
         aria-expanded="true" aria-controls="collapseTwo">
        <i class="fas fa-fw fa-cog"></i>
        <span class="ml-1">{{ $t("backoffice.leftmenu.titleConfig") }}</span>
      </a>
      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">{{ $t("backoffice.leftmenu.titleConfig") }}</h6>
          <a class="collapse-item" href="/admin/configuracion/tipo-promociones">{{ $t("backoffice.leftmenu.titleConfigTipoPromociones")}}</a>
          <a class="collapse-item" href="/admin/configuracion/opciones-generales">{{ $t("backoffice.leftmenu.titleConfigOpcionesGenerales") }}</a>
          <a class="collapse-item" href="/admin/configuracion/listado-agencias">{{ $t("backoffice.leftmenu.titleConfigAgencias") }}</a>
        </div>
      </div>
    </li>


  </ul>
</template>

<script>
export default {
  name: "LeftMenu"
}
</script>

<style scoped>

</style>
