<template>
  <p>Hola login</p>
</template>

<script>
export default {
name: "Login-User"
}
</script>

<style scoped>

</style>
