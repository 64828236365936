<template>
  <TopMenu></TopMenu>
  <div class="container">
    <MenuItemsHome></MenuItemsHome>
  </div>
  <div class="row bg-filter">
    <div class="col-12 mt-3">
      <Filter></Filter>
    </div>
  </div>
  <div class="row">
    <div class="container">
      <div class="row mt-4">
        <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
          <h4>{{ promociones.length }} Promociones encontradas</h4>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-6 col-sm-6">
          <div class="form-inline justify-content-end">
            <label>Ordenar por: </label>
            <select class="form-control">
              <option value=""></option>
              <option value="">Precio (de menor a mayor)</option>
              <option value="">Precio (de mayor a menor)</option>
              <option value="">Destacados</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <PromotionsResult :promotions="promociones"></PromotionsResult>
    </div>
  </div>
</template>

<script>
import TopMenu from "@/components/global/top-menu";
import MenuItemsHome from "@/components/home/MenuItemsHome";
import Filter from "@/components/Filter";
import PromotionsResult from "@/components/front/PromotionsResult";

export default {
  name: "ListadoPromocionesBusqueda",
  components: {PromotionsResult, MenuItemsHome, TopMenu, Filter},
  data() {
    return {
      promociones: []
    }
  },
  mounted() {
    this.getResultPromociones(0, 10, '');
  },
  methods: {
    getResultPromociones(row, rowPerPage, orderBy) {
      let myHeaders = new Headers();
      //myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
      let formData = new FormData();
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData
      };
      if (typeof localStorage.getItem('filterLugar') !== "undefined") {
        formData.append('lugar', localStorage.getItem('filterLugar'));
      }
      if (typeof localStorage.getItem('filterTipo') !== "undefined") {
        formData.append('tipo', localStorage.getItem('filterTipo'));
      }
      if (typeof localStorage.getItem('filterDormitorio') !== "undefined") {
        formData.append('dormitorio', localStorage.getItem('filterDormitorio'));
      }
      if (typeof localStorage.getItem('filterPrecio') !== "undefined") {
        formData.append('precio', localStorage.getItem('filterPrecio'));
      }
      formData.append('row', row);
      formData.append('rowperpage', rowPerPage);
      if (orderBy !== '') {
        formData.append('orderBy', orderBy);
      }
      let url = 'get-filtrado-promociones';
      fetch(this.urlDomain + url, requestOptions).then(async response => {
        const data = await response.json();
        this.promociones = data.aPromociones;
      });
    }
  }
}
</script>

<style scoped>

</style>
